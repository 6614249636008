import React, {
  useEffect, useState, useContext, useMemo, useImperativeHandle, useRef, forwardRef,
} from 'react';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome, faHeartbeat, faBriefcaseMedical, faLongArrowAltLeft,
} from '@fortawesome/fontawesome-free-solid';
import { faQuestionCircle } from '@fortawesome/fontawesome-free-regular';
import {
  parseISO,
  format,
  compareAsc,
} from 'date-fns';
import {
  animateScroll as scroll, scroller,
} from 'react-scroll';
import queryString from 'query-string';
import Modal from 'react-responsive-modal';
import getCompanies from '../../Utils/InsurancesCompaniesStyle';
import {
  customFetch, getDeepObjectValue, getCustomFieldOptions, setDeepObjectValidationFlag, setDeepObjectValue, setNewPartnerForDigitalBroker, validateFields,
} from '../../Utils/Helpers';
import getValidation from '../../Utils/Validation';
import MortgageCheckInfo from './MortgageCheckInfo';
import { ModalMessagesContext } from '../../Utils/ContextsServices/ModalMessagesService';
import LoadingBanner from '../../Utils/UiComponents/LoadingBanner';
import MortgageShortForm from './MortgageShortForm';
import MortgageForm from './MortgageForm';
import MortgageChosenOffer from './MortgageChosenOffer';
import ModalCommercialOfferForm from './ModalCommercialOfferForm';
import getBanks, { getBanksClassifiers, getClassifiers } from '../../Utils/ClassifiersLoaders';
import FormNotificationColorLine from '../../Layout/FormNotifications/FormNotificationColorLine';
import useUserInfoStore from '../../Stores/UserInfoStore';
import { shallow } from 'zustand/shallow';

const FileSaver = require('file-saver');

function MortgageFormContainer(props, ref) {
  const {
    policyStatus,
    policy,
    history,
    copyDraft,
    match,
    location,
    loadPolicy,
  } = props;

  const { theme, userInfo } = useUserInfoStore(
    (state) => ({
      userInfo: state.userInfo,
      theme: state.theme,
    }),
    shallow,
  );

  const defaultSelectValue = { value: '', label: '' };

  const [bankClassifier, setBankClassifier] = useState(null);
  const [banks, setBanks] = useState({});
  const [classifiers, setClassifiers] = useState({});
  const [discountPercent, setDiscountPercent] = useState({});
  const [allowancePercent, setAllowancePercent] = useState({});
  const [adjustmentType, setAdjustmentType] = useState({});
  const [adjustmentTypeOptions, setAdjustmentTypeOptions] = useState([]);
  const [discountPercentLoadingFlag, setDiscountPercentLoadingFlag] = useState({});

  const urlParams = useMemo(() => queryString.parse(location.search), []);

  const insuredAddressRegistrationRef = useRef();
  const creditAgreementAddressRef = useRef();

  const { showModalInfo } = useContext(ModalMessagesContext);

  const commercialOfferRef = useRef(null);

  const [modalGift, setModalGift] = useState(false);
  const [linkToDeal, setLinkToDeal] = useState(null);
  const [customField, setCustomField] = useState(null);
  const [leadSource, setLeadSource] = useState(null);
  const [managerAttracted, setManagerAttracted] = useState(null);
  const [leadSourcesOptions, setLeadSourcesOptions] = useState([]);
  const [customFieldOptions, setCustomFieldOptions] = useState([]);
  const [customFieldName, setCustomFieldName] = useState('');
  const [managerAttractedOptions, setManagerAttractedOptions] = useState([]);
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [errorBlockName, setErrorBlockName] = useState('');
  const [loadInfoFlags, setLoadingInfoFlags] = useState({
    companies: true,
    classifiers: true,
    banks: true,
  });
  const [loadingMortgageInfo, setLoadingMortgageInfo] = useState(true);
  const [draftInfo, setDraftInfo] = useState({
    hash: 0,
    id: 0,
    type: 'mortgage',
  });
  const [showMortgageCheckInfo, setShowMortgageCheckInfo] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const [blurClass, setBlurClass] = useState('');
  const [bankOptions, setBanksOptions] = useState([
    { value: '', label: '' },
  ]);
  const [loadingPolicyTemplate, setLoadingPolicyTemplate] = useState(false);
  const [loadingDeclaration, setLoadingDeclaration] = useState(false);
  const [savingDeclarationFlag, setSavingDeclarationFlag] = useState(false);
  const [modalDeclarationIsOpen, setModalDeclarationIsOpen] = useState(false);
  const [htmlDeclaration, setHtmlDeclaration] = useState('');
  const [newAgent, setNewAgent] = useState({ value: false, label: false });
  const [formData, setFormData] = useState({
    loanBalance: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'loanBalance',
    },
    yearOfConstruction: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'yearOfConstruction',
    },
    birthday: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    lastName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    firstName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    middleName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'name',
    },
    passport: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'passportNumberSerie',
    },
    passportDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    passportDivision: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'plain',
    },
    passportUnitNumber: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'passportUnitNumber',
    },
    birthPlace: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'plain',
    },
    familyState: {
      value: defaultSelectValue,
      errorMessage: '',
      validationRequired: true,
      validationType: 'select',
    },
    organizationName: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'plain',
    },
    insuredAddress: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'address',
    },
    insuredAddressRegistration: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'address',
    },
    height: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'humanHeight',
    },
    weight: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'humanWeight',
    },
    bloodPressureTop: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'bloodPressure',
    },
    bloodPressureLow: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'bloodPressure',
    },
    profession: {
      value: defaultSelectValue,
      errorMessage: '',
      validationRequired: false,
      validationType: 'select',
    },
    buildingType: {
      value: defaultSelectValue,
      errorMessage: '',
      validationRequired: true,
      validationType: 'select',
    },
    buildingSquare: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'decimal',
    },
    objectCost: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'objectCost',
    },
    landCost: {
      value: '',
      errorMessage: '',
      validationRequired: false,
      validationType: 'objectCost',
    },
    roomsCount: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'intNumber',
    },
    buildingFloorCount: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'plain',
    },
    floor: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'plain',
    },
    kadNumber: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'kadNumber',
    },
    wallsMaterial: {
      value: defaultSelectValue,
      errorMessage: '',
      validationRequired: true,
      validationType: 'select',
    },
    ceilingMaterial: {
      value: defaultSelectValue,
      errorMessage: '',
      validationRequired: true,
      validationType: 'select',
    },
    address: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'addressWithFlat',
    },
    correctAddressFlag: {
      value: false,
    },
    correctAddress: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'plain',
    },
    creditAgreementFlag: {
      value: true,
    },
    creditAgreementNumber: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'creditAgreementNumber',
    },
    creditAgreementDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    creditAgreementEndDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    creditAgreementAddressIsObjectAddress: {
      value: false,
    },
    creditAgreementAddress: {
      value: {
        value: '',
        data: {},
      },
      errorMessage: '',
      validationRequired: true,
      validationType: 'city',
    },
    startDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    endDate: {
      value: null,
      errorMessage: '',
      validationRequired: true,
      validationType: 'date',
    },
    contactPhone: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'phone',
    },
    contactEmail: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'email',
    },
    checkConf: {
      value: false,
      errorMessage: '',
      validationRequired: false,
      validationType: 'checkbox',
    },
    personalDataFlag: {
      value: false,
      errorMessage: '',
      validationRequired: true,
      validationType: 'checkbox',
    },
    officeJobFlag: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'checkbox',
    },
    healthInfoFlag: {
      value: false,
      errorMessage: '',
      validationRequired: true,
      validationType: 'checkbox',
    },
    contractInfoFlag: {
      value: false,
      errorMessage: '',
      validationRequired: true,
      validationType: 'checkbox',
    },
    insuranceRulesFlag: {
      value: false,
      errorMessage: '',
      validationRequired: true,
      validationType: 'checkbox',
    },
    insuranceKidFlag: {
      value: false,
      errorMessage: '',
      validationRequired: true,
      validationType: 'checkbox',
    },
    insuranceQuestionsFlag: {
      value: false,
      errorMessage: '',
      validationRequired: true,
      validationType: 'checkbox',
    },
    insuranceDeclarationFlag: {
      value: false,
      errorMessage: '',
      validationRequired: true,
      validationType: 'checkbox',
    },
    noProfitWarningFlag: {
      value: false,
      errorMessage: '',
      validationRequired: true,
      validationType: 'checkbox',
    },
    gender: {
      value: defaultSelectValue,
      errorMessage: '',
      validationRequired: true,
      validationType: 'select',
    },
    bank: {
      value: defaultSelectValue,
      errorMessage: '',
      validationRequired: true,
      validationType: 'select',
    },
    ownership: {
      value: true,
    },
    usingForRent: {
      value: false,
    },
    loanBalanceIncreased: {
      value: false,
      validationRequired: false,
    },
    discountPercent: {
      value: 0,
      errorMessage: '',
      validationRequired: false,
      validationType: 'discountPercent',
    },
    allowancePercent: {
      value: 0,
      errorMessage: '',
      validationRequired: false,
      validationType: 'discountPercent',
    },
    adjustmentType: {
      value: { label: 'Скидка', value: 'discount' },
      errorMessage: '',
      validationRequired: false,
      validationType: 'plain',
    },
    annualRate: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'decimalPercent',
    },
    annualRateWOIns: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'decimalPercent',
    },
    addressRegistrationFlag: {
      value: false,
    },
    fireDangerFlag: {
      value: false,
    },
    dealsCount: {
      value: defaultSelectValue,
      errorMessage: '',
      validationRequired: true,
      validationType: 'select',
    },
    hasGasFlag: {
      value: false,
    },
    newAgreementFlag: {
      validationRequired: false,
      value: false,
    },
    cover: {
      value: defaultSelectValue,
      errorMessage: '',
      validationRequired: true,
      validationType: 'select',
    },
    allowanceDebt: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'plain',
    },
    isNewBuilding: {
      value: false,
      validationType: 'checkbox',
    },
    isInRepairNow: {
      value: false,
      validationType: 'checkbox',
    },
    hasGeneralRepair: {
      value: false,
      validationType: 'checkbox',
    },
    rePlaned: {
      value: false,
      validationType: 'checkbox',
    },
    snils: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'snils',
    },
    isOpenFire: {
      value: false,
    },
    annuityPaymentValue: {
      value: '',
      errorMessage: '',
      validationRequired: true,
      validationType: 'decimal',
    },
    sport: {
      value: defaultSelectValue,
      errorMessage: '',
      validationRequired: false,
      validationType: 'select',
    },
  });
  const [companies, setCompanies] = useState({});
  const [chosenOffer, setChosenOffer] = useState({});
  const [scrollTo, setScrollTo] = useState('');
  const [goCalcFlag, setGoCalcFlag] = useState(false);
  const [calcFlags, setCalcFlags] = useState({
    absolut: {
      buildingFloorCount: false,
      insuredAddressRegistration: false,
    },
    pari: {
      imt: false,
    },
    renessans: {
      buildingFloorCount: false,
    },
  });
  const [phoneCheckRequestFlag, setPhoneCheckRequestFlag] = useState(false);
  const [showForm, setShowForm] = useState('short');
  const [resetCalcFlagsFlag, setResetCalcFlagsFlag] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const [selectedSort, setSelectedSort] = useState('commissionCurrency');
  const [policySortOrderUp, setPolicySortOrderUp] = useState(false);
  const [policyIntegrationsData, setPolicyIntegrationsData] = useState({
    mortgage: [],
    mortgageLife: [],
    mortgageComplex: [],
  });
  const [allPolices, setAllPolices] = useState({
    mortgage: [],
    mortgageLife: [],
    mortgageComplex: [],
  });
  const [showCommission, setShowCommission] = useState(true);
  const [commercialOfferCompanies, setCommercialOfferCompanies] = useState([]);
  const [showCommercialOfferModalForm, setShowCommercialOfferModalForm] = useState('');
  const [loadingCommercialOffer, setLoadingCommercialOffer] = useState(false);

  useEffect(() => {
    if (draftInfo.type === 'mortgage' || draftInfo.type === 'mortgageComplex') {
      setFormData((prev) => ({
        ...prev,
        yearOfConstruction: {
          ...prev.yearOfConstruction,
          validationRequired: true,
        },
      }));
    } else if (draftInfo.type === 'mortgageLife') {
      setFormData((prev) => ({
        ...prev,
        yearOfConstruction: {
          ...prev.yearOfConstruction,
          validationRequired: false,
        },
        hasGasFlag: {
          value: false,
        },
        fireDangerFlag: {
          value: false,
        },
      }));
    }
  }, [draftInfo.type]);

  useEffect(() => {
    if (formData.correctAddressFlag.value) {
      if (!formData.correctAddress.value) {
        setFormData((prev) => ({
          ...prev,
          correctAddress: {
            ...prev.correctAddress,
            value: formData.address.value.value,
            validationRequired: true,
          },
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        correctAddress: {
          ...prev.correctAddress,
          value: '',
          validationRequired: false,
          errorMessage: '',
        },
      }));
    }
  }, [formData.correctAddressFlag.value]);

  useEffect(() => {
    if (draftInfo.type === 'mortgageLife') {
      setFormData((prev) => ({
        ...prev,
        correctAddressFlag: false,
      }));
    }
  }, [draftInfo.type]);

  const resetCalcFlags = () => {
    const newCalcFlags = { ...calcFlags };

    Object.values(newCalcFlags).forEach((skFlags) => {
      Object.keys(skFlags).forEach((flag) => {
        skFlags[flag] = false;
      });
    });

    if (draftInfo.type === 'mortgage') {
      if (parseInt(formData.buildingFloorCount.value, 10) < 4) {
        newCalcFlags.absolut.buildingFloorCount = true;
        newCalcFlags.renessans.buildingFloorCount = true;
      }
    } else {
      const absolutForbiddenRegions = [
        'Чеченская Респ',
        'Респ Ингушетия',
        'Респ Дагестан',
        'Карачаево-Черкесская Респ',
        'Кабардино-Балкарская Респ',
        'Респ Северная Осетия - Алания',
      ];
      if (formData.insuredAddress.value.data && absolutForbiddenRegions.findIndex((region) => region === formData.insuredAddressRegistration.value.data.region_with_type) !== -1) {
        newCalcFlags.absolut.insuredAddressRegistration = true;
      }

      const imt = parseInt(formData.weight.value, 10) / (parseInt(formData.height.value, 10) / 100) ** 2;
      if (imt !== 0 && (imt < 17 || imt > 33)) {
        newCalcFlags.pari.imt = true;
      }
    }
    setCalcFlags(newCalcFlags);
  };

  useEffect(() => {
    if (resetCalcFlags) {
      resetCalcFlags();
    }
  }, [resetCalcFlagsFlag]);

  useEffect(() => {
    setResetCalcFlagsFlag(true);
    if (showForm !== 'short') {
      setShowForm(draftInfo.type);
    }
  }, [draftInfo.type]);

  const checkPhone = () => {
    setPhoneCheckRequestFlag(true);

    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/mortgage/check-phone?product=mortgage&phone=${formData.contactPhone.value}`, {
      headers: {
        Authorization: lsToken,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.check === 'fail') {
          let message = 'На данный телефон уже оформлено 3 или более документов';
          if (response.type === 'agent') {
            message = 'Нельзя указывать телефон, закрепленный за агентом';
          }
          setFormData((prev) => ({
            ...prev,
            contactPhone: {
              ...prev.contactPhone,
              errorMessage: message,
            },
          }));
        }
      })
      .finally(() => {
        setPhoneCheckRequestFlag(false);
      });
  };

  const prepValueToValidate = (value, fieldName) => {
    if (value && typeof value === 'string') {
      value = value.trim();
    }

    if (fieldName === 'contactPhone') {
      checkPhone();
    }

    if (value && (fieldName === 'annualRate' || fieldName === 'annualRateWOIns' || fieldName === 'allowanceDebt')) {
      value = value.replaceAll(',', '.');
      value = parseFloat(value).toFixed(2);
      value = parseFloat(value);
      if (value === 0) {
        value = '1';
      } else if (value > 100) {
        value = '100';
      }
    }

    if (fieldName === 'yearOfConstruction') {
      value = parseInt(value, 10);
      if (value > 2100) {
        value = '2100';
      } else if (value < 1950) {
        value = '1950';
      }
    }
    if (fieldName === 'floor' && value) {
      if (formData.buildingFloorCount) {
        value = parseInt(value, 10);
        const buildingFloorCount = parseInt(formData.buildingFloorCount.value, 10);
        if (value > buildingFloorCount) {
          value = buildingFloorCount;
        }
      }
    }
    if (fieldName === 'buildingFloorCount') {
      if (formData.floor && value) {
        value = parseInt(value, 10);
        const floor = parseInt(formData.floor.value, 10);
        if (floor > value) {
          setFormData((prev) => ({
            ...prev,
            floor: {
              ...prev.floor,
              value,
            },
          }));
        }
      }
    }

    if ((fieldName === 'objectCost' && value !== '') || (fieldName === 'landCost' && value !== '')) {
      value = /^\d+$/.test(formData.objectCost.value) ? parseFloat(value) : '';
    }

    if (typeof value === 'number') {
      value = value.toString();
    }

    if (typeof value === 'string'
      && fieldName === 'passport'
    ) {
      value = value.replace(/ +/g, '');
    }
    return value;
  };

  const postProcessingDate = (value, fieldName, errorMessage) => {
    let resetErrorMessage = errorMessage;
    if (fieldName === 'creditAgreementDate' && formData.creditAgreementDate.value && formData.creditAgreementEndDate.value && formData.creditAgreementEndDate.value < formData.creditAgreementDate.value) {
      resetErrorMessage = 'Не может быть больше даты окончания';
    } else if (fieldName === 'creditAgreementEndDate' && formData.creditAgreementEndDate.value && formData.creditAgreementDate.value && formData.creditAgreementEndDate.value < formData.creditAgreementDate.value) {
      resetErrorMessage = 'Не может быть меньше даты начала';
    } else if (fieldName === 'startDate' && formData.startDate.value && formData.endDate.value && formData.endDate.value < formData.startDate.value) {
      resetErrorMessage = 'Не может быть больше даты окончания';
    } else if (fieldName === 'endDate' && formData.endDate.value && formData.startDate.value && formData.endDate.value < formData.startDate.value) {
      resetErrorMessage = 'Не может быть меньше даты начала';
    }
    return resetErrorMessage;
  };

  const postProcessingAddress = (name, value) => {
    let registrationAddressFlag = formData.addressRegistrationFlag.value;
    let creditAgreementAddressIsObjectAddress = formData.creditAgreementAddressIsObjectAddress.value;
    if (name === 'insuredAddress') {
      registrationAddressFlag = false;
    }
    if (name === 'address') {
      creditAgreementAddressIsObjectAddress = false;
    }
    if (name === 'insuredAddressRegistration') {
      registrationAddressFlag = value === formData.insuredAddress.value.value;
      const absolutForbiddenRegions = [
        'Чеченская Респ',
        'Респ Ингушетия',
        'Респ Дагестан',
        'Карачаево-Черкесская Респ',
        'Кабардино-Балкарская Респ',
        'Респ Северная Осетия - Алания',
      ];
      let flag = false;
      if (formData.insuredAddress.value.data && absolutForbiddenRegions.findIndex((region) => region === formData.insuredAddressRegistration.value.data.region_with_type) !== -1) {
        flag = true;
      }
      setCalcFlags((prev) => ({
        ...prev,
        absolut: {
          ...prev,
          insuredAddressRegistration: flag,
        },
      }));
    }

    setFormData((prev) => ({
      ...prev,
      addressRegistrationFlag: {
        ...prev.addressRegistrationFlag,
        value: registrationAddressFlag,
      },
      creditAgreementAddressIsObjectAddress: {
        ...prev.creditAgreementAddressIsObjectAddress,
        value: creditAgreementAddressIsObjectAddress,
      },
    }));
  };

  const postProcessingInput = (value, fieldName, errorMessage) => {
    if (fieldName === 'loanBalance' && value && formData.objectCost.value && parseFloat(value) > parseFloat(formData.objectCost.value)) {
      errorMessage = 'Не может быть больше стоимости объекта';
    } else if (fieldName === 'loanBalance' && formData.objectCost.errorMessage) {
      setFormData((prev) => ({
        ...prev,
        objectCost: {
          ...prev.objectCost,
          errorMessage: '',
        },
      }));
    }
    if (fieldName === 'objectCost' && value && formData.loanBalance.value && parseFloat(formData.loanBalance.value) > parseFloat(value)) {
      errorMessage = 'Не может быть меньше суммы кредита';
    } else if (fieldName === 'loanBalance' && formData.loanBalance.errorMessage) {
      setFormData((prev) => ({
        ...prev,
        loanBalance: {
          ...prev.loanBalance,
          errorMessage: '',
        },
      }));
    }
    return errorMessage;
  };

  const handleValidate = (e, args = {}) => {
    let fieldName = '';
    if (e) {
      const { target } = e;
      fieldName = target.id;
    } else {
      fieldName = args.fieldName;
    }
    const path = args.path ? `${args.path}.${fieldName}` : fieldName;
    let value = getDeepObjectValue(formData, path);
    let errorMessage = value.error;
    value = value.data;
    if (errorMessage) {
      errorMessage = value.error;
    } else {
      value = prepValueToValidate(value, fieldName);

      errorMessage = getValidation(value, formData[fieldName].validationType);

      if (fieldName === 'multiSelect' && (formData[fieldName].value === null || !formData[fieldName].value.length)) {
        errorMessage = 'Выберите хотя бы один вариант';
      }

      if (fieldName === 'creditAgreementDate'
        || fieldName === 'creditAgreementEndDate'
        || fieldName === 'startDate'
        || fieldName === 'endDate') {
        errorMessage = postProcessingDate(value, fieldName, errorMessage);
      }

      if (fieldName === 'insuredAddress'
        || fieldName === 'address'
        || fieldName === 'insuredAddressRegistration') {
        postProcessingAddress();
      }

      if (fieldName === 'loanBalance' || fieldName === 'objectCost' || fieldName === 'landCost') {
        errorMessage = postProcessingInput(value, fieldName, errorMessage);
      }

      if (fieldName === 'bank' || fieldName === 'gender' || fieldName === 'birthday'
        || ((fieldName === 'creditAgreementDate' || fieldName === 'creditAgreementAddress') && (formData.bank.value.value === 'vtb' || formData.bank.value.value === 'otkrytie'))) {
        setBlurClass('blur');
      }
    }
    setDeepObjectValue(formData, setFormData, path, value, errorMessage);
  };

  const setAddressEnd = (e) => {
    const { target } = e;
    target.setSelectionRange(target.value.length, target.value.length);
  };

  const setCursorToEnd = (e) => {
    e.target.onfocus = setAddressEnd;
  };

  const toggleFlag = (e) => {
    const { target } = e;
    setFormData((prev) => ({
      ...prev,
      [target.id]: {
        ...prev[target.id],
        value: !prev[target.id].value,
        errorMessage: '',
      },
    }));
    if (draftInfo.id && (target.id === 'creditAgreementFlag' || target.id === 'ownership' || target.id === 'usingForRent' || target.id === 'hasGasFlag' || target.id === 'fireDangerFlag')) {
      setBlurClass('blur');
    }
  };

  const getCompaniesList = (type) => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    getCompanies(lsToken, type).then((response) => {
      setCompanies(response);
      setLoadingInfoFlags((prev) => ({
        ...prev,
        companies: false,
      }));
    });
  };

  const getBanksList = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    getBanks(lsToken).then((response) => {
      const banksList = {};
      const banksOptionList = [];
      response.forEach((bank) => {
        banksList[bank.code] = { name: bank.name, logo: bank.logo };
        banksOptionList.push({ value: bank.code, label: bank.name });
      });
      setBanks(banksList);
      setBanksOptions(banksOptionList);
      setLoadingInfoFlags((prev) => ({
        ...prev,
        banks: false,
      }));
    });
  };
  const checkDiscount = (data, polisOnlineCode) => (data.find((item) => item.polisOnlineCode === polisOnlineCode) || {}).discountPercentUnlock ?? false;

  const getBanksClassifiersList = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    getBanksClassifiers(lsToken).then((response) => {
      const arrayResponse = Object.entries(response);
      setBankClassifier(arrayResponse);
      const defaultMaskPercent = {
        value: 0,
        errorMessage: '',
        validationRequired: true,
        validationType: '',
        bankList: [],
      };
      const newAdjustmentTypeOptions = [];
      arrayResponse.map((data) => {
        const discountBanks = data[1].filter((bankData) => bankData.discountPercentUnlock).map((bankData) => bankData.polisOnlineCode);
        const allowanceBanks = data[1].filter((bankData) => bankData.allowancePercentUnlock).map((bankData) => bankData.polisOnlineCode);
        setDiscountPercent((prev) => ({
          ...prev,
          [data[0]]: {
            ...defaultMaskPercent,
            validationType: `discountPercent${data[0].charAt(0).toUpperCase() + data[0].slice(1)}`,
            bankList: discountBanks,
          },
        }));
        setAllowancePercent((prev) => ({
          ...prev,
          [data[0]]: {
            ...defaultMaskPercent,
            validationType: `allowancePercent${data[0].charAt(0).toUpperCase() + data[0].slice(1)}`,
            bankList: allowanceBanks,
          },
        }));
        discountBanks.forEach((discountBank) => {
          if (!newAdjustmentTypeOptions[data[0]]) {
            newAdjustmentTypeOptions[data[0]] = {};
          }
          if (!newAdjustmentTypeOptions[data[0]][discountBank]) {
            newAdjustmentTypeOptions[data[0]][discountBank] = [];
          }
          newAdjustmentTypeOptions[data[0]][discountBank].push({ label: 'Скидка, %', value: 'discount' });
        });
        allowanceBanks.forEach((allowanceBank) => {
          if (!newAdjustmentTypeOptions[data[0]]) {
            newAdjustmentTypeOptions[data[0]] = {};
          }
          if (!newAdjustmentTypeOptions[data[0]][allowanceBank]) {
            newAdjustmentTypeOptions[data[0]][allowanceBank] = [];
          }
          newAdjustmentTypeOptions[data[0]][allowanceBank].push({ label: 'Надбавка, %', value: 'allowance' });
        });
        setDiscountPercentLoadingFlag((prev) => ({ ...prev, [data[0]]: false }));
      });
      setAdjustmentTypeOptions(newAdjustmentTypeOptions);
    });
  };

  const getClassifiersList = () => {
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    getClassifiers(lsToken, 'mortgage').then((response) => {
      setClassifiers(response);
      setLoadingInfoFlags((prev) => ({
        ...prev,
        classifiers: false,
      }));
    });
  };

  useEffect(() => {
    if (bankOptions.length) {
      setFormData((prev) => ({
        ...prev,
        bank: {
          ...prev.bank,
          value: bankOptions[0],
        },
      }));
    }
  }, [bankOptions]);

  const getPrivacyPolicy = () => {
    fetch(`${process.env.REACT_APP_API_DOMAIN}/admin_documents/privacy_policy`, {
      method: 'get',
    })
      .then((response) => response.json())
      .then((response) => {
        setPrivacyPolicy(response.path);
      });
  };

  const makeFormDataFlatJson = () => {
    const flatJson = {};
    Object.keys(formData).forEach((key) => {
      flatJson[key] = formData[key].value;
    });

    return flatJson;
  };

  const validateForm = () => {
    const formFields = { ...formData };
    const validationResult = validateFields(formFields);
    let newErrorBlockName = '';
    newErrorBlockName = Object.keys(validationResult.result).find(((key) => validationResult.result[key].errorMessage));
    const newFormData = { ...formData };
    Object.keys(validationResult.result).forEach((field) => {
      newFormData[field] = validationResult.result[field];
    });
    setFormData(newFormData);
    if (newErrorBlockName) {
      setErrorBlockName(newErrorBlockName);
      setScrollTo('errorBlock');
    }
    return validationResult.validationSuccess;
  };

  const getPolicySample = (e) => {
    e.preventDefault();
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/mortgage/get-policy-sample`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        draftHash: draftInfo.hash,
        insurance: chosenOffer.companyCode,
      }),
    })
      .then((response) => response.blob())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        } else if (response) {
          let fileName = '';
          switch (draftInfo.type) {
            case 'mortgage':
              fileName = `mortgage_${chosenOffer.companyCode}`;
              break;
            case 'mortgageLife':
              fileName = `mortgage_life_${chosenOffer.companyCode}`;
              break;
            case 'mortgageComplex':
              fileName = `mortgage_complex_${chosenOffer.companyCode}`;
              break;
            default:
              break;
          }
          FileSaver.saveAs(response, `${fileName}.zip`);
        }
      })
      .finally(() => {
        setLoadingPolicyTemplate(false);
      });
  };

  const getDeclaration = (e) => {
    if (typeof e !== 'undefined') {
      e.preventDefault();
    }
    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/mortgage/get-declaration`, {
      method: 'post',
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        draftHash: draftInfo.hash,
        insurance: chosenOffer.companyCode,
      }),
    })
      .then((response) => (chosenOffer.companyCode === 'sogaz' ? response.json() : response.blob()))
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        } else if (response) {
          if (chosenOffer.companyCode === 'sogaz') {
            setModalDeclarationIsOpen(true);
            setHtmlDeclaration(response.html);
          } else {
            let fileName = '';
            switch (draftInfo.type) {
              case 'mortgage':
                fileName = `declaration_mortgage_${chosenOffer.companyCode}`;
                break;
              case 'mortgageLife':
                fileName = `declaration_mortgage_life_${chosenOffer.companyCode}`;
                break;
              case 'mortgageComplex':
                fileName = `declaration_mortgage_complex_${chosenOffer.companyCode}`;
                break;
              default:
                break;
            }
            FileSaver.saveAs(response, `${fileName}.pdf`);
          }
        }
      })
      .finally(() => {
        setLoadingDeclaration(false);
        setSavingDeclarationFlag(false);
      });
  };

  const saveDraft = (e, goCalc = false, skipValidation = false, declaration = false, policySample = false) => {
    if (typeof e !== 'undefined') {
      e.preventDefault();
    }
    const formIsValid = skipValidation || validateForm();
    if (formIsValid) {
      if (policySample) {
        setLoadingPolicyTemplate(true);
      }
      if (declaration) {
        setLoadingDeclaration(true);
      }
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;

      const data = makeFormDataFlatJson();
      data.passportDate = data.passportDate ? `${format(data.passportDate, 'yyyy-MM-dd')} 00:00:00` : null;
      data.birthday = data.birthday ? `${format(data.birthday, 'yyyy-MM-dd')} 00:00:00` : null;
      data.creditAgreementDate = data.creditAgreementDate ? `${format(data.creditAgreementDate, 'yyyy-MM-dd')} 00:00:00` : null;
      data.creditAgreementEndDate = data.creditAgreementEndDate ? `${format(data.creditAgreementEndDate, 'yyyy-MM-dd')} 00:00:00` : null;
      data.startDate = data.startDate ? `${format(data.startDate, 'yyyy-MM-dd')} 00:00:00` : null;
      data.endDate = data.endDate ? `${format(data.endDate, 'yyyy-MM-dd')} 00:00:00` : null;
      data.annualRate = data.annualRate ? data.annualRate.replace(',', '.') : data.annualRate;
      data.annualRateWOIns = data.annualRateWOIns ? data.annualRateWOIns.replace(',', '.') : data.annualRateWOIns;
      data.allowanceDebt = data.allowanceDebt ? data.allowanceDebt.replace(',', '.') : data.allowanceDebt;
      data.loanBalance = data.loanBalance ? data.loanBalance.replace(',', '.') : data.loanBalance;

      if (draftInfo.id) {
        customFetch(`${process.env.REACT_APP_API_DOMAIN}/mortgage/${draftInfo.id}`, {
          method: 'put',
          headers: {
            Authorization: lsToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data,
            product: draftInfo.type,
            leadSource,
            linkToDeal,
            managerAttracted,
            newAgent: newAgent.value,
            customField: customField ? customField.value : null,
          }),
        }).then((response) => response.json())
          .then(() => {
            if (declaration) {
              getDeclaration(e);
            }
            if (policySample) {
              getPolicySample(e);
            }
            if (goCalc) {
              if (showForm === 'short') {
                setGoCalcFlag(true);
              } else {
                setShowMortgageCheckInfo(true);
                setScrollTo('check_info');
              }
            } else {
              if (showCommercialOfferModalForm) {
                if (showCommercialOfferModalForm === 'send') {
                  commercialOfferRef.current.open();
                  setShowCommercialOfferModalForm('');
                } else {
                  setLoadingCommercialOffer(true);
                  commercialOfferRef.current.getPdf(e);
                }
              }
              showModalInfo('Черновик сохранен');
            }
          })
          .catch(() => {
            showModalInfo('Ошибка');
          });
      } else {
        customFetch(`${process.env.REACT_APP_API_DOMAIN}/mortgage`, {
          method: 'post',
          headers: {
            Authorization: lsToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data,
            product: draftInfo.type,
            leadSource,
            linkToDeal,
            managerAttracted,
            newAgent: newAgent.value,
            customField: customField ? customField.value : null,
          }),
        }).then((response) => response.json())
          .then((newDraft) => {
            if (declaration) {
              getDeclaration(e);
            }
            if (policySample) {
              getPolicySample(e);
            }
            setDraftInfo((prev) => ({
              ...prev,
              id: newDraft.id,
              hash: newDraft.hash,
            }));
            if (goCalc) {
              setGoCalcFlag(true);
              setScrollTo('check_info');
            } else {
              showModalInfo('Черновик сохранен');
            }
          })
          .catch(() => {
            showModalInfo('Ошибка');
          });
      }
    } else {
      setLoadingDeclaration(false);
    }
  };

  const loadPolicyData = () => {
    const draftData = policy.form_data;
    let newStartDate = null;
    let newEndDate = null;
    let diffDays = 0;
    if (policy.status !== 'complete' && policy.status !== 'processing') {
      if (draftData.startDate && compareAsc(parseISO(draftData.startDate), new Date()) === 1) {
        newStartDate = parseISO(draftData.startDate.substring(0, 10));
        diffDays = 0;
      } else {
        newStartDate = new Date(new Date().setHours(0, 0, 0, 0));
        newStartDate.setDate(newStartDate.getDate() + 1);
        newStartDate = new Date(newStartDate);
        if (draftData.startDate) {
          const oldStartDate = parseISO(draftData.startDate.substring(0, 10));
          const diffTime = Math.abs(newStartDate - oldStartDate);
          diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        }
      }
    } else if (draftData.startDate) {
      newStartDate = parseISO(draftData.startDate.substring(0, 10));
    }
    if ((draftData.endDate && compareAsc(parseISO(draftData.startDate), new Date()) === 1) || policy.status === 'complete' || policy.status === 'processing') {
      newEndDate = parseISO(draftData.endDate.substring(0, 10));
    } else if (newStartDate) {
      if (chosenOffer.companyCode
        && ((formData.bank.value.value && formData.bank.value.value !== 'unicredit' && formData.bank.value.value !== 'uralfd' && chosenOffer.companyCode === 'pari')
        || (chosenOffer.companyCode === 'amt' && formData.newAgreementFlag.value)
        || chosenOffer.companyCode === 'ugoria'
        || (chosenOffer.companyCode === 'alpha' && formData.bank.value.value !== 'unicredit')
        || ((chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second') && ['vtb', 'mkb', 'bgfbank', 'mtsbank', 'domrf', 'alfabank'].includes(formData.bank.value.value))
        || chosenOffer.companyCode === 'vsk'
        || chosenOffer.companyCode === 'soglasie'
        || (chosenOffer.companyCode === 'alpha-bank' && formData.bank.value.value === 'mkb')
        || formData.bank.value.value === 'otkrytie'
        || formData.bank.value.value === 'alfabank'
        || formData.bank.value.value === 'vtb')
        && formData.creditAgreementEndDate.value
        && formData.bank.value.value !== 'sberbank'
        && chosenOffer.companyCode !== 'rshbstrah'
      ) {
        newEndDate = new Date(formData.creditAgreementEndDate.value.getTime());
      } else if (draftData.endDate) {
        newEndDate = new Date(parseISO(draftData.endDate));
        newEndDate = newEndDate.setDate(newEndDate.getDate() + diffDays);
        newEndDate = new Date(newEndDate);
      } else {
        newEndDate = new Date();
        newEndDate = newEndDate.setFullYear(newStartDate.getFullYear() + 1);
        newEndDate = new Date(newEndDate);
      }
    }

    const birthday = draftData.birthday ? parseISO(draftData.birthday.substring(0, 10)) : null;
    const passportDate = draftData.passportDate ? parseISO(draftData.passportDate.substring(0, 10)) : null;
    const creditAgreementDate = draftData.creditAgreementDate ? parseISO(draftData.creditAgreementDate.substring(0, 10)) : null;
    const creditAgreementEndDate = draftData.creditAgreementEndDate ? parseISO(draftData.creditAgreementEndDate.substring(0, 10)) : null;

    const genderValue = draftData.gender && classifiers.api.gender.filter((option) => option.value === draftData.gender.value).length ? draftData.gender : classifiers.api.gender[0];
    const bankValue = draftData.bank && bankOptions.filter((option) => option.value === draftData.bank.value).length ? draftData.bank : bankOptions[0];
    const buildingTypeValue = draftData.buildingType && classifiers.api.buildingType.filter((option) => option.value === draftData.buildingType.value).length ? draftData.buildingType : classifiers.api.buildingType[0];
    const dealsCountValue = draftData.dealsCount && classifiers.api.dealsCount.filter((option) => option.value === draftData.dealsCount.value).length ? draftData.dealsCount : classifiers.api.dealsCount[0];
    const coverValue = draftData.cover && classifiers.api.cover.filter((option) => option.value === draftData.cover.value).length ? draftData.cover : classifiers.api.cover;
    const familyStateValue = draftData.familyState && classifiers.api.familyState.filter((option) => option.value === draftData.familyState.value).length ? draftData.familyState : classifiers.api.familyState[0];
    const wallsMaterialValue = draftData.wallsMaterial && classifiers.api.wallsMaterial.filter((option) => option.value === draftData.wallsMaterial.value).length ? draftData.wallsMaterial : classifiers.api.wallsMaterial[0];
    const ceilingMaterialValue = draftData.ceilingMaterial && classifiers.api.ceilingMaterial.filter((option) => option.value === draftData.ceilingMaterial.value).length ? draftData.ceilingMaterial : classifiers.api.ceilingMaterial[0];
    const sportValue = draftData.sport && classifiers.sogaz.sport.filter((option) => option.value === draftData.sport.value).length ? draftData.sport : classifiers.sogaz.sport;
    const professionValue = draftData.profession && classifiers.sogaz.profession.filter((option) => option.value === draftData.profession.value).length ? draftData.profession : classifiers.sogaz.profession;

    const errorMessageStartDate = newStartDate && newEndDate && newEndDate < newStartDate ? 'Не может быть больше даты окончания' : '';
    const errorMessageEndDate = newEndDate && newStartDate && newEndDate < newStartDate ? 'Не может быть меньше даты начала' : '';
    const errorMessageCreditDate = creditAgreementDate && creditAgreementEndDate && creditAgreementEndDate < creditAgreementDate ? 'Не может быть больше даты окончания' : '';
    const errorMessageCreditEndDate = creditAgreementDate && creditAgreementEndDate && creditAgreementEndDate < creditAgreementDate ? 'Не может быть меньше даты начала' : '';

    setDraftInfo({
      type: policy.product_insurance,
      id: policy.id,
      hash: policy.hash,
    });
    setLeadSource(policy.lead_source ? policy.lead_source : null);
    setManagerAttracted(policy.manager_attracted ? policy.manager_attracted : null);
    setCustomField(policy.custom_field ? { label: policy.custom_field.name, value: policy.custom_field.id } : null);
    setLinkToDeal(policy.link_to_deal ? policy.link_to_deal : null);
    const data = {
      loanBalance: {
        ...formData.loanBalance,
        value: draftData.loanBalance,
      },
      yearOfConstruction: {
        ...formData.yearOfConstruction,
        value: draftData.yearOfConstruction,
      },
      birthday: {
        ...formData.birthday,
        value: birthday,
      },
      lastName: {
        ...formData.lastName,
        value: draftData.lastName,
      },
      firstName: {
        ...formData.firstName,
        value: draftData.firstName,
      },
      middleName: {
        ...formData.middleName,
        value: draftData.middleName,
      },
      passport: {
        ...formData.passport,
        value: draftData.passport,
      },
      passportDate: {
        ...formData.passportDate,
        value: passportDate,
      },
      passportDivision: {
        ...formData.passportDivision,
        value: draftData.passportDivision,
      },
      passportUnitNumber: {
        ...formData.passportUnitNumber,
        value: draftData.passportUnitNumber,
      },
      birthPlace: {
        ...formData.birthPlace,
        value: draftData.birthPlace || '',
      },
      familyState: {
        ...formData.familyState,
        value: familyStateValue,
      },
      organizationName: {
        ...formData.organizationName,
        value: draftData.organizationName || '',
      },
      insuredAddress: {
        ...formData.insuredAddress,
        value: draftData.insuredAddress,
      },
      insuredAddressRegistration: {
        ...formData.insuredAddressRegistration,
        value: draftData.insuredAddressRegistration,
      },
      height: {
        ...formData.height,
        value: draftData.height,
      },
      weight: {
        ...formData.weight,
        value: draftData.weight,
      },
      bloodPressureTop: {
        ...formData.bloodPressureTop,
        value: draftData.bloodPressureTop,
      },
      bloodPressureLow: {
        ...formData.bloodPressureLow,
        value: draftData.bloodPressureLow,
      },
      profession: {
        ...formData.profession,
        value: professionValue,
      },
      buildingType: {
        ...formData.buildingType,
        value: buildingTypeValue,
      },
      buildingSquare: {
        ...formData.buildingSquare,
        value: draftData.buildingSquare,
      },
      objectCost: {
        ...formData.objectCost,
        value: draftData.objectCost,
      },
      landCost: {
        ...formData.landCost,
        value: draftData.landCost,
      },
      roomsCount: {
        ...formData.roomsCount,
        value: draftData.roomsCount || '',
      },
      buildingFloorCount: {
        ...formData.buildingFloorCount,
        value: draftData.buildingFloorCount,
      },
      floor: {
        ...formData.floor,
        value: draftData.floor,
      },
      kadNumber: {
        ...formData.kadNumber,
        value: draftData.kadNumber || '',
      },
      wallsMaterial: {
        ...formData.wallsMaterial,
        value: wallsMaterialValue,
      },
      ceilingMaterial: {
        ...formData.ceilingMaterial,
        value: ceilingMaterialValue,
      },
      address: {
        ...formData.address,
        value: draftData.address,
        validationType: (policy.company_code === 'energogarant' && policy.product_insurance !== 'mortgageComplex') ? 'region' : ((draftData.buildingType && draftData.buildingType.value === 'FLAT') ? 'addressWithFlat' : 'address'),
      },
      correctAddressFlag: {
        ...formData.correctAddressFlag,
        value: draftData.correctAddressFlag || false,
      },
      correctAddress: {
        ...formData.correctAddress,
        value: draftData.correctAddress || '',
      },
      creditAgreementFlag: {
        ...formData.creditAgreementFlag,
        value: draftData.creditAgreementFlag,
      },
      addressRegistrationFlag: {
        ...formData.addressRegistrationFlag,
        value: draftData.addressRegistrationFlag,
      },
      creditAgreementNumber: {
        ...formData.creditAgreementNumber,
        value: draftData.creditAgreementNumber,
      },
      creditAgreementDate: {
        ...formData.creditAgreementDate,
        value: creditAgreementDate,
        errorMessage: formData.creditAgreementDate.errorMessage ? formData.creditAgreementDate.errorMessage : errorMessageCreditDate,
      },
      creditAgreementEndDate: {
        ...formData.creditAgreementEndDate,
        value: creditAgreementEndDate,
        errorMessage: formData.creditAgreementEndDate.errorMessage ? formData.creditAgreementEndDate.errorMessage : errorMessageCreditEndDate,
      },
      creditAgreementAddress: {
        ...formData.creditAgreementAddress,
        value: draftData.creditAgreementAddress,
      },
      creditAgreementAddressIsObjectAddress: {
        ...formData.creditAgreementAddressIsObjectAddress,
        value: draftData.creditAgreementAddressIsObjectAddress,
      },
      startDate: {
        ...formData.startDate,
        value: newStartDate,
        errorMessage: formData.startDate.errorMessage ? formData.startDate.errorMessage : errorMessageStartDate,
      },
      endDate: {
        ...formData.endDate,
        value: newEndDate,
        errorMessage: formData.endDate.errorMessage ? formData.endDate.errorMessage : errorMessageEndDate,
      },
      contactPhone: {
        ...formData.contactPhone,
        value: draftData.contactPhone,
      },
      contactEmail: {
        ...formData.contactEmail,
        value: draftData.contactEmail,
      },
      gender: {
        ...formData.gender,
        value: genderValue,
      },
      bank: {
        ...formData.bank,
        value: bankValue,
      },
      ownership: {
        ...formData.ownership,
        value: draftData.ownership,
      },
      usingForRent: {
        ...formData.usingForRent,
        value: draftData.usingForRent,
      },
      loanBalanceIncreased: {
        ...formData.loanBalanceIncreased,
        value: draftData.loanBalanceIncreased,
      },
      annualRate: {
        ...formData.annualRate,
        value: draftData.annualRate,
      },
      annualRateWOIns: {
        ...formData.annualRateWOIns,
        value: draftData.annualRateWOIns,
      },
      dealsCount: {
        ...formData.dealsCount,
        value: dealsCountValue,
      },
      hasGasFlag: {
        ...formData.hasGasFlag,
        value: draftData.hasGasFlag,
      },
      fireDangerFlag: {
        ...formData.fireDangerFlag,
        value: draftData.fireDangerFlag,
      },
      personalDataFlag: {
        ...formData.personalDataFlag,
        value: draftData.personalDataFlag || false,
      },
      checkConf: {
        ...formData.checkConf,
        value: draftData.checkConf || false,
      },
      officeJobFlag: {
        ...formData.officeJobFlag,
        value: draftData.officeJobFlag || false,
      },
      healthInfoFlag: {
        ...formData.healthInfoFlag,
        value: draftData.healthInfoFlag || false,
      },
      contractInfoFlag: {
        ...formData.contractInfoFlag,
        value: draftData.contractInfoFlag || false,
      },
      insuranceRulesFlag: {
        ...formData.insuranceRulesFlag,
        value: draftData.insuranceRulesFlag || false,
      },
      insuranceKidFlag: {
        ...formData.insuranceKidFlag,
        value: draftData.insuranceKidFlag || false,
      },
      insuranceQuestionsFlag: {
        ...formData.insuranceQuestionsFlag,
        value: draftData.insuranceQuestionsFlag || false,
      },
      insuranceDeclarationFlag: {
        ...formData.insuranceDeclarationFlag,
        value: draftData.insuranceDeclarationFlag || false,
      },
      noProfitWarningFlag: {
        ...formData.noProfitWarningFlag,
        value: draftData.noProfitWarningFlag || false,
      },
      newAgreementFlag: {
        ...formData.newAgreementFlag,
        value: draftData.newAgreementFlag,
      },
      cover: {
        ...formData.cover,
        value: coverValue,
      },
      sport: {
        ...formData.sport,
        value: sportValue,
      },
      allowanceDebt: {
        ...formData.allowanceDebt,
        value: draftData.allowanceDebt || '',
      },
      isNewBuilding: {
        ...formData.isNewBuilding,
        value: draftData.isNewBuilding || false,
      },
      isInRepairNow: {
        ...formData.isInRepairNow,
        value: draftData.isInRepairNow || false,
      },
      hasGeneralRepair: {
        ...formData.hasGeneralRepair,
        value: draftData.hasGeneralRepair || false,
      },
      rePlaned: {
        ...formData.rePlaned,
        value: draftData.rePlaned || false,
      },
      snils: {
        ...formData.snils,
        value: draftData.snils || '',
      },
      annuityPaymentValue: {
        ...formData.annuityPaymentValue,
        value: draftData.annuityPaymentValue || '',
      },
      isOpenFire: {
        ...formData.isOpenFire,
        value: draftData.isOpenFire || false,
      },
    };
    setFormData(data);
    setResetCalcFlagsFlag(true);
    if (policy.company_code && policy.company) {
      setChosenOffer({
        companyCode: policy.company_code,
        companyName: policy.company,
        integration: 'direct',
        price: policy.cost,
      });
      setShowForm(policy.product_insurance);
    }

    setNewPartnerForDigitalBroker(policy, setNewAgent);

    setDisableField(policy.status !== 'pending');
    setLoadingMortgageInfo(false);
  };

  const loadClassifiersData = () => {
    setFormData((prev) => {
      const newFormData = { ...prev };
      Object.entries(newFormData).forEach(([key, value]) => {
        if (key !== 'bank' && value.validationType === 'select') {
          if (key === 'sport' || key === 'profession') {
            value.value = classifiers.sogaz[key][0];
          } else {
            value.value = classifiers.api[key][0];
          }
        }
      });
      return newFormData;
    });
  };

  const copyForm = () => {
    copyDraft();
    setLoadingInit(false);
    setShowForm('short');
    setShowMortgageCheckInfo(false);
    setChosenOffer({});
    setPolicyIntegrationsData({
      mortgage: [],
      mortgageLife: [],
      mortgageComplex: [],
    });
    setAllPolices({
      mortgage: [],
      mortgageLife: [],
      mortgageComplex: [],
    });
    setDisableField(false);
  };

  const goToBuying = (e) => {
    e.preventDefault();
    if (!policy.is_demo) {
      saveDraft(e, true);
    } else {
      setShowMortgageCheckInfo(true);
    }
  };

  const goToFullForm = (e, companyCode, price, companyName, integration, commission) => {
    setChosenOffer({
      companyCode,
      companyName,
      integration,
      price,
      commission,
    });
    setShowForm(draftInfo.type);
    setScrollTo('chosen-offer');
  };

  const backToForm = (e, scrollTarget = '', onlyShowData = false) => {
    e.preventDefault();
    setShowMortgageCheckInfo(false);
    if (scrollTarget === 'short') {
      setShowForm('short');
      if (!allPolices[draftInfo.type].length && !onlyShowData) {
        setGoCalcFlag(true);
        scrollTarget = 'searchResults';
      } else {
        scrollTarget = 'mortgage_container';
      }
    }
    setScrollTo(scrollTarget);
  };

  const makeRequestForSupport = () => {
    let insuranceId = '';
    let object = '';

    const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
    customFetch(`${process.env.REACT_APP_API_DOMAIN}/policy/mortgage/${policy.id}/get-insurance-id`, {
      headers: {
        Authorization: lsToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          showModalInfo(response.error, 'error');
        }

        if (draftInfo.type === 'mortgageLife') {
          object = 'страхователь';
        } else {
          object = `${formData.buildingType.value.label}`;
          if (formData.buildingSquare.value) {
            object = `${object}, ${formData.buildingSquare.value}м2`;
          }
          if (formData.address.value.value) {
            object = `${object}, ${formData.address.value.value}`;
          }
        }

        if (response.insuranceId) {
          insuranceId = `: ${response.insuranceId}`;
        }

        const requestText = `
Добрый день!

Посмотрите пож-та расчет${insuranceId}
Страхователь: ${formData.lastName.value} ${formData.firstName.value} ${formData.middleName.value}
Дата рождения: ${format(formData.birthday.value, 'dd.MM.yyyy')}
Объект: ${object}
Суть проблемы:`;

        setTimeout(async () => await navigator.clipboard.writeText(requestText));
        showModalInfo('Текст запроса в поддержку СК скопирован в буфер обмена');
      })
      .catch(() => {
        showModalInfo('Ошибка получения id расчета выбранной СК', 'error');
      });
  };

  useEffect(() => {
    let flag = false;
    if (formData.creditAgreementDate.value) {
      const today = new Date();
      const twoMonthAgo = new Date(today.setMonth(today.getMonth() - 2));
      const twoMonthPlus = new Date(today.setMonth(today.getMonth() + 2));
      if (formData.creditAgreementDate.value > twoMonthAgo && formData.creditAgreementDate.value < twoMonthPlus) {
        flag = true;
      }
    }
    setFormData((prev) => ({
      ...prev,
      newAgreementFlag: {
        ...prev.newAgreementFlag,
        value: flag,
      },
    }));
  }, [formData.creditAgreementDate.value]);

  useEffect(() => {
    if (scrollTo !== '') {
      switch (scrollTo) {
        case 'searchResults':
          scroller.scrollTo(scrollTo, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -70,
          });
          break;
        case 'chosen-offer':
          scroller.scrollTo(scrollTo, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -100,
          });
          break;
        case 'top':
          scroll.scrollToTop({
            duration: 1500,
            delay: 100,
            smooth: true,
          });
          break;
        case 'errorBlock':
          scroller.scrollTo(errorBlockName, {
            duration: 1200,
            delay: 100,
            smooth: true,
            offset: -100,
          });
          break;
        default:
          scroller.scrollTo(scrollTo, {
            duration: 600,
            smooth: true,
            offset: -100,
          });
          break;
      }
    }
    setScrollTo('');
  }, [scrollTo]);

  useImperativeHandle(ref, () => ({
    copyForm: () => {
      copyForm();
    },
    makeRequestForSupport: () => {
      makeRequestForSupport();
    },
  }));

  useEffect(() => {
    if (policy.id !== 0) {
      if (policy.status !== 'pending') {
        getCompaniesList('all');
      } else {
        getCompaniesList('mortgage');
      }
    } else if (!match.params.id) {
      getCompaniesList('mortgage');
    }
  }, [policy.id]);

  useEffect(() => {
    if (!loadInfoFlags.companies && !loadInfoFlags.banks && !loadInfoFlags.classifiers) {
      if (match.params.id) {
        if (policy.id !== 0) {
          loadPolicyData(policy.id);
        }
      } else {
        loadClassifiersData();
        setLoadingMortgageInfo(false);
      }
    }
  }, [loadInfoFlags.companies, policy.id, loadInfoFlags.banks, loadInfoFlags.classifiers]);

  useEffect(() => {
    if (draftInfo.id) {
      let link = `/mortgage/${draftInfo.id}`;
      if (urlParams.adminEdit) {
        link = `/mortgage/${draftInfo.id}?adminEdit=1`;
      }
      window.history.pushState(null, null, link);
    }
  }, [draftInfo.id]);

  useEffect(() => {
    if (draftInfo.id) {
      setBlurClass('blur');
    }
  }, [draftInfo.type]);

  useEffect(() => {
    if (!formData.creditAgreementFlag.value) {
      setFormData((prev) => ({
        ...prev,
        creditAgreementDate: {
          ...prev.creditAgreementDate,
          value: '',
          errorMessage: '',
          validationRequired: false,
        },
        creditAgreementEndDate: {
          ...prev.creditAgreementEndDate,
          value: '',
          errorMessage: '',
          validationRequired: false,
        },
        creditAgreementNumber: {
          ...prev.creditAgreementNumber,
          value: '',
          errorMessage: '',
          validationRequired: false,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        creditAgreementDate: {
          ...prev.creditAgreementDate,
          validationRequired: showForm !== 'short',
        },
        creditAgreementEndDate: {
          ...prev.creditAgreementEndDate,
          validationRequired: (formData.bank.value.value !== 'sberbank' && formData.bank.value.value && formData.bank.value.value !== 'uralfd' && formData.bank.value.value !== 'unicredit' && chosenOffer.companyCode === 'pari')
            || (chosenOffer.companyCode === 'amt' && formData.newAgreementFlag.value)
            || chosenOffer.companyCode === 'ugoria'
            || (chosenOffer.companyCode === 'alpha' && (formData.bank.value.value !== 'sberbank' && formData.bank.value.value !== 'unicredit'))
            || ((chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second') && ['vtb', 'mkb', 'bgfbank', 'mtsbank', 'domrf', 'alfabank'].includes(formData.bank.value.value))
            || chosenOffer.companyCode === 'vsk'
            || chosenOffer.companyCode === 'rgs'
            || chosenOffer.companyCode === 'soglasie'
            || chosenOffer.companyCode === 'psb'
            || (chosenOffer.companyCode === 'alpha-bank' && formData.bank.value.value === 'mkb')
            || (formData.bank.value.value === 'otkrytie' && showForm !== 'short')
            || (formData.bank.value.value === 'alfabank' && showForm !== 'short')
            || (formData.bank.value.value === 'vtb' && showForm !== 'short')
            || chosenOffer.companyCode === 'liberty',
        },
        creditAgreementNumber: {
          ...prev.creditAgreementNumber,
          validationRequired: showForm !== 'short',
        },
      }));
    }
  }, [formData.creditAgreementFlag.value]);

  useEffect(() => {
    if (policy.status !== 'complete' && policy.status !== 'processing') {
      if (formData.creditAgreementFlag.value && (
        (formData.bank.value.value !== 'sberbank' && formData.bank.value.value && formData.bank.value.value !== 'uralfd' && formData.bank.value.value !== 'unicredit' && chosenOffer.companyCode === 'pari')
        || (chosenOffer.companyCode === 'amt' && formData.newAgreementFlag.value)
        || chosenOffer.companyCode === 'ugoria'
        || (chosenOffer.companyCode === 'alpha' && (formData.bank.value.value !== 'sberbank' && formData.bank.value.value !== 'unicredit'))
        || ((chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second') && ['vtb', 'mkb', 'bgfbank', 'mtsbank', 'domrf', 'alfabank'].includes(formData.bank.value.value))
        || chosenOffer.companyCode === 'vsk'
        || chosenOffer.companyCode === 'soglasie'
        || (chosenOffer.companyCode === 'alpha-bank' && formData.bank.value.value === 'mkb')
        || (formData.bank.value.value === 'otkrytie' && showForm !== 'short')
        || (formData.bank.value.value === 'alfabank' && showForm !== 'short')
        || (formData.bank.value.value === 'vtb' && chosenOffer.companyCode !== 'rgs-bank-canal' && chosenOffer.companyCode !== 'rgs' && chosenOffer.companyCode !== 'maks' && chosenOffer.companyCode !== 'liberty' && chosenOffer.companyCode !== 'astro_volga' && chosenOffer.companyCode !== 'vsk' && showForm !== 'short')
      )) {
        setFormData((prev) => ({
          ...prev,
          creditAgreementEndDate: {
            ...prev.creditAgreementEndDate,
            errorMessage: '',
            validationRequired: true,
          },
        }));
      } else {
        let newEndDate = null;
        if (formData.startDate.value) {
          newEndDate = new Date(formData.startDate.value.getTime());
          newEndDate = newEndDate.setFullYear(newEndDate.getFullYear() + 1);
          newEndDate = new Date(newEndDate);
          newEndDate = newEndDate.setDate(newEndDate.getDate() - 1);
          newEndDate = new Date(newEndDate);
        }
        const tempFormData = { ...formData };
        tempFormData.endDate.value = newEndDate;
        tempFormData.endDate.errorMessage = '';
        setFormData(tempFormData);
      }
    }
  }, [chosenOffer.companyCode, formData.bank.value, formData.newAgreementFlag.value]);

  useEffect(() => {
    let newValue = formData.creditAgreementNumber.value;
    if (formData.bank.value.value === 'sberbank' && formData.creditAgreementNumber.value && formData.creditAgreementNumber.value.length > 8) {
      newValue = '';
    }
    setFormData((prev) => ({
      ...prev,
      creditAgreementNumber: {
        ...prev.creditAgreementNumber,
        errorMessage: '',
        value: newValue,
      },
    }));
  }, [formData.bank.value.value]);

  useEffect(() => {
    if (formData.startDate.value) {
      let newEndDate = null;
      if (((formData.bank.value.value && formData.bank.value.value !== 'uralfd' && formData.bank.value.value !== 'unicredit' && chosenOffer.companyCode === 'pari')
        || (chosenOffer.companyCode === 'amt' && formData.newAgreementFlag.value)
        || (chosenOffer.companyCode === 'ugoria')
        || (chosenOffer.companyCode === 'alpha' && formData.bank.value.value !== 'unicredit')
        || ((chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second') && ['vtb', 'mkb', 'bgfbank', 'mtsbank', 'domrf', 'alfabank'].includes(formData.bank.value.value))
        || chosenOffer.companyCode === 'soglasie'
        || formData.bank.value.value === 'otkrytie'
        || formData.bank.value.value === 'alfabank'
        || (chosenOffer.companyCode === 'alpha-bank' && formData.bank.value.value === 'mkb')
        || (formData.bank.value.value === 'vtb' && chosenOffer.companyCode !== 'rgs-bank-canal' && chosenOffer.companyCode !== 'rgs' && chosenOffer.companyCode !== 'maks' && chosenOffer.companyCode !== 'liberty' && chosenOffer.companyCode !== 'astro_volga' && chosenOffer.companyCode !== 'vsk'))
        && formData.creditAgreementEndDate.value
        && chosenOffer.companyCode !== 'rshbstrah'
        && formData.bank.value.value !== 'sberbank') {
        newEndDate = new Date(formData.creditAgreementEndDate.value.getTime());
      } else {
        newEndDate = new Date(formData.startDate.value.getTime());
        newEndDate = newEndDate.setFullYear(newEndDate.getFullYear() + 1);
        newEndDate = new Date(newEndDate);
        newEndDate = newEndDate.setDate(newEndDate.getDate() - 1);
        newEndDate = new Date(newEndDate);
      }

      if (chosenOffer.companyCode === 'vsk' && formData.creditAgreementEndDate.value && formData.creditAgreementEndDate.value < newEndDate) {
        const newCreditAgreementEndDate = newEndDate;
        setFormData((prev) => ({
          ...prev,
          creditAgreementEndDate: {
            ...prev.creditAgreementEndDate,
            value: newCreditAgreementEndDate,
            errorMessage: '',
          },
        }));
      }
      setFormData((prev) => ({
        ...prev,
        endDate: {
          ...prev.endDate,
          value: newEndDate,
          errorMessage: '',
        },
      }));
    }
    if (!formData.startDate.errorMessage && formData.endDate.value && formData.endDate.errorMessage) {
      setFormData((prev) => ({
        ...prev,
        endDate: {
          ...prev.endDate,
          errorMessage: '',
        },
      }));
    }
  }, [formData.startDate.value]);

  useEffect(() => {
    if (((formData.bank.value.value && formData.bank.value.value !== 'uralfd' && formData.bank.value.value !== 'unicredit' && chosenOffer.companyCode === 'pari')
      || (chosenOffer.companyCode === 'amt' && formData.newAgreementFlag.value)
      || (chosenOffer.companyCode === 'ugoria')
      || (chosenOffer.companyCode === 'alpha' && formData.bank.value.value !== 'unicredit')
      || ((chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second') && ['vtb', 'mkb', 'bgfbank', 'mtsbank', 'domrf', 'alfabank'].includes(formData.bank.value.value))
      || chosenOffer.companyCode === 'soglasie'
      || formData.bank.value.value === 'otkrytie'
      || formData.bank.value.value === 'alfabank'
      || (chosenOffer.companyCode === 'alpha-bank' && formData.bank.value.value === 'mkb')
      || (formData.bank.value.value === 'vtb' && chosenOffer.companyCode !== 'rgs-bank-canal' && chosenOffer.companyCode !== 'rgs' && chosenOffer.companyCode !== 'maks' && chosenOffer.companyCode !== 'liberty' && chosenOffer.companyCode !== 'astro_volga' && chosenOffer.companyCode !== 'vsk'))
      && formData.creditAgreementEndDate.value
      && chosenOffer.companyCode !== 'rshbstrah'
      && formData.bank.value.value !== 'sberbank') {
      const newEndDate = new Date(formData.creditAgreementEndDate.value.getTime());
      const tempFormData = { ...formData };
      tempFormData.endDate.value = newEndDate;
      tempFormData.endDate.errorMessage = '';
      setFormData(tempFormData);
    }
  }, [formData.creditAgreementEndDate.value, chosenOffer.companyCode, formData.bank.value]);

  useEffect(() => {
    if (formData.addressRegistrationFlag.value) {
      setFormData((prev) => ({
        ...prev,
        insuredAddressRegistration: {
          ...prev.insuredAddressRegistration,
          value: prev.insuredAddress.value,
          errorMessage: '',
          validationRequired: true,
        },
      }));
      if (insuredAddressRegistrationRef.current) {
        insuredAddressRegistrationRef.current.state.query = formData.insuredAddress.value.value;
        insuredAddressRegistrationRef.current.state.inputQuery = formData.insuredAddress.value.value;
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        insuredAddressRegistration: {
          ...prev.insuredAddressRegistration,
          value: {
            value: '',
            data: {},
          },
          errorMessage: '',
          validationRequired: true,
        },
      }));
      if (insuredAddressRegistrationRef.current) {
        insuredAddressRegistrationRef.current.state.query = '';
        insuredAddressRegistrationRef.current.state.inputQuery = '';
      }
    }
  }, [formData.addressRegistrationFlag.value]);

  useEffect(() => {
    if (formData.creditAgreementAddressIsObjectAddress.value) {
      setFormData((prev) => ({
        ...prev,
        creditAgreementAddress: {
          ...prev.creditAgreementAddress,
          value: {
            value: prev.address.value.data.city_with_type ? prev.address.value.data.city_with_type : prev.address.value.data.settlement_with_type,
            data: prev.address.value.data,
          },
          errorMessage: '',
          validationRequired: true,
        },
      }));
      if (creditAgreementAddressRef.current) {
        creditAgreementAddressRef.current.state.query = formData.address.value.data.city_with_type ? formData.address.value.data.city_with_type : formData.address.value.data.settlement_with_type;
        creditAgreementAddressRef.current.state.inputQuery = formData.address.value.data.city_with_type ? formData.address.value.data.city_with_type : formData.address.value.data.settlement_with_type;
      }
    }
  }, [formData.creditAgreementAddressIsObjectAddress.value]);

  useEffect(() => {
    if (chosenOffer.companyCode && chosenOffer.companyCode === 'energogarant' && draftInfo.type !== 'mortgageComplex') {
      setFormData((prev) => {
        const newFormData = { ...prev };
        newFormData.address.errorMessage = '';
        newFormData.address.validationType = 'region';
        return newFormData;
      });
    } else {
      setFormData((prev) => {
        const newFormData = { ...prev };
        newFormData.address.errorMessage = '';
        newFormData.address.validationType = newFormData.buildingType.value.value === 'FLAT' ? 'addressWithFlat' : 'address';
        return newFormData;
      });
    }
    if (draftInfo.type === 'mortgageLife' || formData.bank.value.value === 'vtb') {
      setFormData((prev) => {
        const newFormData = { ...prev };
        newFormData.creditAgreementAddressIsObjectAddress.value = false;
        return newFormData;
      });
    }
    if (chosenOffer.companyCode) {
      if ((chosenOffer.companyCode !== 'sogaz' && chosenOffer.companyCode !== 'pari') || draftInfo.type === 'mortgage') {
        setFormData((prev) => {
          const newFormData = { ...prev };
          newFormData.sport.errorMessage = '';
          newFormData.sport.validationRequired = false;
          newFormData.sport.value = defaultSelectValue;
          newFormData.profession.errorMessage = '';
          newFormData.profession.validationRequired = false;
          newFormData.profession.value = defaultSelectValue;
          return newFormData;
        });
      } else {
        setFormData((prev) => {
          const newFormData = { ...prev };
          newFormData.sport.validationRequired = chosenOffer.companyCode !== 'pari';
          newFormData.profession.validationRequired = true;
          return newFormData;
        });
      }
    }
  }, [chosenOffer.companyCode, draftInfo.type]);

  useEffect(() => {
    if ((chosenOffer.companyCode && chosenOffer.companyCode !== 'alpha-bank' && chosenOffer.companyCode !== 'alpha' && chosenOffer.companyCode !== 'zetta' && chosenOffer.companyCode !== 'renessans' && chosenOffer.companyCode !== 'pari') || draftInfo.type === 'mortgage') {
      setFormData((prev) => ({
        ...prev,
        healthInfoFlag: {
          ...prev.healthInfoFlag,
          errorMessage: '',
          validationRequired: false,
        },
        officeJobFlag: {
          ...prev.officeJobFlag,
          errorMessage: '',
          validationRequired: false,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        healthInfoFlag: {
          ...prev.healthInfoFlag,
          validationRequired: true,
        },
        officeJobFlag: {
          ...prev.officeJobFlag,
          validationRequired: true,
        },
      }));
    }
  }, [chosenOffer.companyCode]);

  useEffect(() => {
    if (formData.bank.value.value === 'sberbank') {
      setFormData((prev) => ({
        ...prev,
        ownership: {
          value: true,
        },
      }));
    }
    setFormData((prev) => ({
      ...prev,
      discountPercent: {
        ...prev.discountPercent,
        errorMessage: '',
        value: 0,
      },
      allowancePercent: {
        ...prev.allowancePercent,
        errorMessage: '',
        value: 0,
      },
      adjustmentType: {
        ...prev.adjustmentType,
        errorMessage: '',
        value: { label: 'Скидка', value: 'discount' },
      },
    }));
  }, [formData.bank.value]);

  useEffect(() => {
    if (!formData.creditAgreementDate.errorMessage && formData.creditAgreementEndDate.value && formData.creditAgreementEndDate.errorMessage) {
      setFormData((prev) => ({
        ...prev,
        creditAgreementEndDate: {
          ...prev.creditAgreementEndDate,
          errorMessage: '',
        },
      }));
    }
  }, [formData.creditAgreementDate.value]);

  useEffect(() => {
    if (!formData.creditAgreementEndDate.errorMessage && formData.creditAgreementDate.value && formData.creditAgreementDate.errorMessage) {
      setFormData((prev) => ({
        ...prev,
        creditAgreementDate: {
          ...prev.creditAgreementDate,
          errorMessage: '',
        },
      }));
    }
  }, [formData.creditAgreementEndDate.value]);

  useEffect(() => {
    if (!formData.endDate.errorMessage && formData.startDate.value && formData.startDate.errorMessage) {
      setFormData((prev) => ({
        ...prev,
        startDate: {
          ...prev.startDate,
          errorMessage: '',
        },
      }));
    }
  }, [formData.endDate.value]);

  useEffect(() => {
    if ((chosenOffer.companyCode === 'amt' || chosenOffer.companyCode === 'sk-kardif' || chosenOffer.companyCode === 'ingoss') && formData.bank.value.value === 'vtb') {
      if (!formData.loanBalanceIncreased.value) {
        const newLoanBalance = parseFloat(formData.loanBalance.value) + ((parseFloat(formData.loanBalance.value) / 100) * 10);
        setFormData((prev) => {
          const newFormData = { ...prev };
          newFormData.loanBalance.value = newLoanBalance.toString();
          newFormData.loanBalanceIncreased.value = true;
          return newFormData;
        });
      }
    } else {
      let newLoanBalance = formData.loanBalance.value;
      let loanBalanceIncreasedValue = formData.loanBalanceIncreased.value;
      if (formData.loanBalanceIncreased.value) {
        newLoanBalance = (parseFloat(formData.loanBalance.value) - ((parseFloat(formData.loanBalance.value) / 110) * 10)).toString();
        loanBalanceIncreasedValue = !loanBalanceIncreasedValue;
      }
      setFormData((prev) => {
        const newFormData = { ...prev };
        newFormData.loanBalance.value = newLoanBalance.toString();
        newFormData.loanBalanceIncreased.value = loanBalanceIncreasedValue;
        return newFormData;
      });
    }
  }, [chosenOffer.companyCode, formData.bank.value.value]);

  useEffect(() => {
    if (showForm === 'short' && formData.loanBalanceIncreased.value) {
      let newLoanBalance = formData.loanBalance.value;
      let loanBalanceIncreasedValue = formData.loanBalanceIncreased.value;
      newLoanBalance = (parseFloat(formData.loanBalance.value) - ((parseFloat(formData.loanBalance.value) / 110) * 10)).toString();
      loanBalanceIncreasedValue = !loanBalanceIncreasedValue;
      setFormData((prev) => {
        const newFormData = { ...prev };
        newFormData.loanBalance.value = newLoanBalance.toString();
        newFormData.loanBalanceIncreased.value = loanBalanceIncreasedValue;
        return newFormData;
      });
    }
  }, [showForm]);

  useEffect(() => {
    if (userInfo.full_time_employee === 1 && process.env.REACT_APP_API_DOMAIN === 'https://api.polis.online/api') {
      const lsToken = `Bearer ${localStorage.getItem('id_token')}`;
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/lead_sources`, {
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          setLeadSourcesOptions(response.map((source) => ({ value: source.id, label: source['Представление'] })));
        })
        .catch((err) => {
          console.log(`Error Reading data ${err}`);
        });
      customFetch(`${process.env.REACT_APP_API_DOMAIN}/managers`, {
        headers: {
          Authorization: lsToken,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          setManagerAttractedOptions(response.map((manager) => ({ value: manager.id, label: manager['Представление'] })));
        })
        .catch((err) => {
          console.log(`Error Reading data ${err}`);
        });
    }
  }, [userInfo.full_time_employee]);

  useEffect(() => {
    const flagPaths = {
      lastName: showCommercialOfferModalForm || showForm !== 'short',
      firstName: showCommercialOfferModalForm || showForm !== 'short',
      middleName: showCommercialOfferModalForm || showForm !== 'short',
      buildingType: showCommercialOfferModalForm || showForm !== 'short',
      buildingSquare: showCommercialOfferModalForm || (showForm !== 'short' && (draftInfo.type !== 'mortgageLife' || chosenOffer.companyCode === 'alpha')),
      startDate: showCommercialOfferModalForm || showForm !== 'short',
      endDate: showCommercialOfferModalForm || showForm !== 'short',
      contactPhone: showCommercialOfferModalForm || showForm !== 'short',
      contactEmail: showCommercialOfferModalForm || showForm !== 'short',
      bank: showForm === 'short' && !showCommercialOfferModalForm,
      loanBalance: showForm === 'short' && !showCommercialOfferModalForm,
      yearOfConstruction: showForm === 'short' && !showCommercialOfferModalForm && draftInfo.type !== 'mortgageLife' && formData.buildingType.value.value !== 'LAND_PLOT',
      birthday: showForm === 'short' && !showCommercialOfferModalForm,
      gender: showForm === 'short' && !showCommercialOfferModalForm,
      passport: showForm !== 'short' && !showCommercialOfferModalForm,
      passportDate: showForm !== 'short' && !showCommercialOfferModalForm,
      insuredAddress: showForm !== 'short' && !showCommercialOfferModalForm,
      insuredAddressRegistration: showForm !== 'short' && !showCommercialOfferModalForm,
      annualRate: showForm !== 'short' && !showCommercialOfferModalForm && (draftInfo.type !== 'mortgageLife'
        || chosenOffer.companyCode === 'zetta'
        || chosenOffer.companyCode === 'soglasie'
        || chosenOffer.companyCode === 'vsk'
        || chosenOffer.companyCode === 'psb'
        || chosenOffer.companyCode === 'energogarant'
        || chosenOffer.companyCode === 'ugoria'
        || (chosenOffer.companyCode === 'alpha' && formData.bank.value.value !== 'sberbank')
        || chosenOffer.companyCode === 'alpha-bank'
        || chosenOffer.companyCode === 'alpha-retail'
        || chosenOffer.companyCode === 'liberty'
        || chosenOffer.companyCode === 'rgs-bank-canal'
        || chosenOffer.companyCode === 'rgs'
        || (chosenOffer.companyCode === 'pari' && (formData.bank.value.value === 'vtb' || formData.bank.value.value === 'gazpropm' || formData.bank.value.value === 'raiffeisen' || formData.bank.value.value === 'spb' || formData.bank.value.value === 'abr'))
        || ((chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second') && ['vtb', 'mkb', 'bgfbank', 'mtsbank', 'domrf', 'alfabank'].includes(formData.bank.value.value))),
      annualRateWOIns: showForm !== 'short' && !showCommercialOfferModalForm && (chosenOffer.companyCode === 'rshbstrah'
        || chosenOffer.companyCode === 'liberty'
        || chosenOffer.companyCode === 'rgs-bank-canal'
        || chosenOffer.companyCode === 'vsk'
        || chosenOffer.companyCode === 'rgs'
        || ((chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second')  && formData.bank.value.value === 'alfabank')
        || (chosenOffer.companyCode === 'soglasie' && ['mortgageLife', 'mortgageComplex'].includes(draftInfo.type))
        || (chosenOffer.companyCode === 'energogarant' && formData.bank.value.value !== 'sberbank')
        || (chosenOffer.companyCode === 'ugoria' && (formData.bank.value.value === 'rosselhozbank' || formData.bank.value.value === 'alfabank'))),
      address: showForm !== 'short' && !showCommercialOfferModalForm && (draftInfo.type !== 'mortgageLife'
        || chosenOffer.companyCode === 'alpha'),
      creditAgreementNumber: showForm !== 'short' && !showCommercialOfferModalForm && formData.creditAgreementFlag.value,
      creditAgreementDate: (showForm !== 'short' || formData.bank.value.value === 'vtb' || formData.bank.value.value === 'domrf') && !showCommercialOfferModalForm && formData.creditAgreementFlag.value,
      creditAgreementEndDate: formData.creditAgreementFlag.value && showForm !== 'short' && !showCommercialOfferModalForm
        && ((formData.bank.value.value !== 'sberbank' && formData.bank.value.value && formData.bank.value.value !== 'uralfd' && formData.bank.value.value !== 'unicredit' && chosenOffer.companyCode === 'pari')
          || (chosenOffer.companyCode === 'amt' && formData.newAgreementFlag.value)
          || chosenOffer.companyCode === 'ugoria'
          || (chosenOffer.companyCode === 'alpha' && (formData.bank.value.value !== 'sberbank' && formData.bank.value.value !== 'unicredit'))
          || ((chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second') && ['vtb', 'mkb', 'bgfbank', 'mtsbank', 'domrf', 'alfabank'].includes(formData.bank.value.value))
          || chosenOffer.companyCode === 'vsk'
          || chosenOffer.companyCode === 'rgs'
          || chosenOffer.companyCode === 'psb'
          || (chosenOffer.companyCode === 'alpha-bank' && formData.bank.value.value === 'mkb')
          || (chosenOffer.companyCode === 'energogarant' && formData.bank.value.value !== 'sberbank')
          || chosenOffer.companyCode === 'soglasie'
          || formData.bank.value.value === 'otkrytie'
          || formData.bank.value.value === 'alfabank'
          || formData.bank.value.value === 'vtb'
          || chosenOffer.companyCode === 'liberty'
          || chosenOffer.companyCode === 'rgs-bank-canal'),
      passportDivision: showForm !== 'short' && !showCommercialOfferModalForm,
      passportUnitNumber: showForm !== 'short' && !showCommercialOfferModalForm,
      buildingFloorCount: showForm !== 'short' && !showCommercialOfferModalForm && draftInfo.type !== 'mortgageLife' && formData.buildingType.value.value !== 'LAND_PLOT',
      floor: showForm !== 'short' && !showCommercialOfferModalForm && draftInfo.type !== 'mortgageLife' && formData.buildingType.value.value !== 'HOUSE',
      objectCost: showForm !== 'short' && !showCommercialOfferModalForm && draftInfo.type !== 'mortgageLife',
      landCost: showForm !== 'short' && !showCommercialOfferModalForm && draftInfo.type !== 'mortgageLife' && chosenOffer.companyCode === 'astro_volga' && (formData.buildingType.value.value === 'LAND_PLOT' || formData.buildingType.value.value === 'RESIDENTIAL_BUILDING_WITH_LAND_PLOT'),
      creditAgreementAddress: (showForm === 'short' && formData.bank.value.value === 'vtb') || (showForm !== 'short' && !showCommercialOfferModalForm
        && (draftInfo.type !== 'mortgageLife' || chosenOffer.companyCode === 'alpha-retail'
          || chosenOffer.companyCode === 'alpha'
          || chosenOffer.companyCode === 'ingoss'
          || (chosenOffer.companyCode === 'sogaz' && ['mortgageLife', 'mortgageComplex'].includes(draftInfo.type))
          || (chosenOffer.companyCode === 'vsk' && draftInfo.type === 'mortgage')
          || (chosenOffer.companyCode === 'pari' && formData.bank.value.value === 'vtb')
          || ((chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second') && ['vtb', 'mkb', 'bgfbank', 'mtsbank', 'domrf', 'alfabank'].includes(formData.bank.value.value)))),
      height: showForm !== 'short' && !showCommercialOfferModalForm
        && draftInfo.type !== 'mortgage' && chosenOffer.companyCode !== 'sk-kardif',
      weight: showForm !== 'short' && !showCommercialOfferModalForm
        && draftInfo.type !== 'mortgage' && chosenOffer.companyCode !== 'sk-kardif',
      bloodPressureTop: showForm !== 'short' && !showCommercialOfferModalForm
        && draftInfo.type !== 'mortgage' && chosenOffer.companyCode !== 'sk-kardif',
      bloodPressureLow: showForm !== 'short' && !showCommercialOfferModalForm
        && draftInfo.type !== 'mortgage' && chosenOffer.companyCode !== 'sk-kardif',
      personalDataFlag: showForm !== 'short' && !showCommercialOfferModalForm,
      officeJobFlag: showForm !== 'short' && !showCommercialOfferModalForm
        && (draftInfo.type !== 'mortgage'
          && (chosenOffer.companyCode === 'alpha'
            || chosenOffer.companyCode === 'alpha-bank'
            || chosenOffer.companyCode === 'zetta'
            || (chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second')
            || chosenOffer.companyCode === 'rgs-bank-canal'
            || chosenOffer.companyCode === 'pari'
            || chosenOffer.companyCode === 'astro_volga')),
      healthInfoFlag: showForm !== 'short' && !showCommercialOfferModalForm
        && (draftInfo.type !== 'mortgage'
          && (chosenOffer.companyCode === 'alpha'
            || chosenOffer.companyCode === 'alpha-bank'
            || chosenOffer.companyCode === 'zetta'
            || (chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second')
            || chosenOffer.companyCode === 'rgs-bank-canal'
            || chosenOffer.companyCode === 'pari'
            || chosenOffer.companyCode === 'astro_volga')),
      contractInfoFlag: showForm !== 'short' && !showCommercialOfferModalForm,
      insuranceRulesFlag: showForm !== 'short' && !showCommercialOfferModalForm,
      insuranceKidFlag: showForm !== 'short' && !showCommercialOfferModalForm && (chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second'),
      insuranceQuestionsFlag: showForm !== 'short' && !showCommercialOfferModalForm && chosenOffer.companyCode === 'ugoria',
      insuranceDeclarationFlag: showForm !== 'short' && !showCommercialOfferModalForm && companies[chosenOffer.companyCode].declaration,
      noProfitWarningFlag: showForm !== 'short' && !showCommercialOfferModalForm && chosenOffer.companyCode === 'renessans' && formData.bank.value.value === 'domrf',
      correctAddress: showForm !== 'short' && !showCommercialOfferModalForm && formData.correctAddressFlag.value,
      cover: showForm !== 'short' && !showCommercialOfferModalForm && (chosenOffer.companyCode === 'vsk' && draftInfo.type === 'mortgage'),
      wallsMaterial: showForm !== 'short' && !showCommercialOfferModalForm && (
        (chosenOffer.companyCode === 'vsk' && draftInfo.type === 'mortgage')
        || (chosenOffer.companyCode === 'energogarant' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type))
        || (chosenOffer.companyCode === 'alpha-retail' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type))
        || (chosenOffer.companyCode === 'ugoria' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type))
        || (chosenOffer.companyCode === 'rgs-bank-canal' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type))
        || (chosenOffer.companyCode === 'liberty' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type))
        || (chosenOffer.companyCode === 'astro_volga' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type))
        || (chosenOffer.companyCode === 'rshbstrah' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type))
        || (chosenOffer.companyCode === 'soglasie' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type))
        || (chosenOffer.companyCode === 'ingoss' && formData.buildingType.value.value === 'HOUSE' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type))
        || ((chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second') && draftInfo.type !== 'mortgageLife' && ['vtb', 'mkb', 'bgfbank', 'mtsbank', 'domrf', 'alfabank'].includes(formData.bank.value.value))),
      ceilingMaterial: showForm !== 'short' && !showCommercialOfferModalForm && ((chosenOffer.companyCode === 'vsk' && draftInfo.type === 'mortgage')
        || (chosenOffer.companyCode === 'energogarant' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type))
        || (chosenOffer.companyCode === 'ugoria' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type))
        || (chosenOffer.companyCode === 'rshbstrah' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type))
        || (chosenOffer.companyCode === 'soglasie' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type))
        || (chosenOffer.companyCode === 'ingoss' && formData.buildingType.value.value === 'HOUSE' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type))
        || (chosenOffer.companyCode === 'rgs-bank-canal' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type))
        || ((chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second') && draftInfo.type !== 'mortgageLife' && ['vtb', 'mkb', 'bgfbank', 'mtsbank', 'domrf', 'alfabank'].includes(formData.bank.value.value))),
      sport: showForm !== 'short' && !showCommercialOfferModalForm && (chosenOffer.companyCode === 'sogaz' && ['mortgageLife', 'mortgageComplex'].includes(draftInfo.type)),
      profession: showForm !== 'short' && !showCommercialOfferModalForm && ((chosenOffer.companyCode === 'sogaz' || chosenOffer.companyCode === 'pari') && ['mortgageLife', 'mortgageComplex'].includes(draftInfo.type)),
      birthPlace: showForm !== 'short' && !showCommercialOfferModalForm
        && ((chosenOffer.companyCode === 'vsk')
        || chosenOffer.companyCode === 'rgs'
        || chosenOffer.companyCode === 'ugoria'
        || (chosenOffer.companyCode === 'sogaz' && ['mortgageLife', 'mortgageComplex'].includes(draftInfo.type))
        || ((chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second') && ['vtb', 'mkb', 'bgfbank', 'mtsbank', 'domrf', 'alfabank'].includes(formData.bank.value.value))
        || chosenOffer.companyCode === 'liberty'),
      allowanceDebt: showForm !== 'short' && !showCommercialOfferModalForm && ((chosenOffer.companyCode === 'sk-kardif' || chosenOffer.companyCode === 'amt') && formData.bank.value.value === 'vtb'),
      kadNumber: showForm !== 'short' && !showCommercialOfferModalForm
        && (chosenOffer.companyCode === 'alpha-retail'
          || chosenOffer.companyCode === 'rgs'
          || chosenOffer.companyCode === 'psb'
          || chosenOffer.companyCode === 'energogarant'
          || (chosenOffer.companyCode === 'alpha' && formData.bank.value.value !== 'sberbank' && formData.bank.value.value !== 'unicredit')
          || (chosenOffer.companyCode === 'sogaz' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type))
          || chosenOffer.companyCode === 'rshbstrah'
          || formData.bank.value.value === 'tkbbank'
          || formData.bank.value.value === 'investtorgbank'
          || chosenOffer.companyCode === 'ugoria')
        && draftInfo.type !== 'mortgageLife',
      familyState: showForm !== 'short' && !showCommercialOfferModalForm && (chosenOffer.companyCode === 'ugoria'
        || ((chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second') && ['vtb', 'mkb', 'bgfbank', 'mtsbank', 'domrf', 'alfabank'].includes(formData.bank.value.value))),
      snils: showForm !== 'short' && !showCommercialOfferModalForm && (chosenOffer.companyCode === 'ugoria'
        || ((chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second') && draftInfo.type !== 'mortgage' && ['vtb', 'mkb', 'bgfbank', 'mtsbank', 'domrf', 'alfabank'].includes(formData.bank.value.value))),
      annuityPaymentValue: showForm !== 'short' && !showCommercialOfferModalForm
        && (((chosenOffer.companyCode === 'renessans' || chosenOffer.companyCode === 'renessans-second') && ['vtb', 'bgfbank'].includes(formData.bank.value.value))
          || (chosenOffer.companyCode === 'ingoss' && formData.bank.value.value === 'domrf' && draftInfo.type === 'mortgage')
          || (chosenOffer.companyCode === 'zetta' && formData.bank.value.value === 'vtb')
          || chosenOffer.companyCode === 'liberty'),
      dealsCount: showForm !== 'short' && !showCommercialOfferModalForm && chosenOffer.companyCode === 'ugoria' && ['mortgage', 'mortgageComplex'].includes(draftInfo.type),
      roomsCount: showForm !== 'short' && !showCommercialOfferModalForm && (chosenOffer.companyCode === 'ugoria' || chosenOffer.companyCode === 'liberty' || (chosenOffer.companyCode === 'rshbstrah' && formData.buildingType.value.value !== 'LAND_PLOT')) && ['mortgage', 'mortgageComplex'].includes(draftInfo.type),
      organizationName: showForm !== 'short' && !showCommercialOfferModalForm && chosenOffer.companyCode === 'ugoria' && ['mortgageLife', 'mortgageComplex'].includes(draftInfo.type),
    };
    setDeepObjectValidationFlag(formData, setFormData, flagPaths);
  }, [showForm, showCommercialOfferModalForm, chosenOffer.companyCode, formData.bank.value, formData.buildingType.value.value]);

  useEffect(() => {
    if (formData.buildingType.value.value === 'FLAT') {
      setFormData((prev) => ({
        ...prev,
        address: {
          ...formData.address,
          validationType: 'addressWithFlat',
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        address: {
          ...formData.address,
          validationType: 'address',
        },
      }));
    }
  }, [formData.buildingType.value.value]);

  useEffect(() => {
    if (classifiers.api) {
      if (formData.fireDangerFlag.value) {
        setFormData((prev) => ({
          ...prev,
          ceilingMaterial: {
            ...formData.ceilingMaterial,
            value: classifiers.api.ceilingMaterial.filter((option) => option.value === 'WOOD')[0],
          },
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          ceilingMaterial: {
            ...formData.ceilingMaterial,
            value: classifiers.api.ceilingMaterial.filter((option) => option.value === 'CONCRETE')[0],
          },
        }));
      }
    }
  }, [formData.fireDangerFlag.value]);

  useEffect(() => {
    if (formData.ceilingMaterial.value) {
      if (formData.ceilingMaterial.value.value === 'WOOD' || formData.ceilingMaterial.value.value === 'MIXED') {
        setFormData((prev) => ({
          ...prev,
          fireDangerFlag: {
            ...formData.fireDangerFlag,
            value: true,
          },
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          fireDangerFlag: {
            ...formData.fireDangerFlag,
            value: false,
          },
        }));
      }
    }
  }, [formData.ceilingMaterial.value]);

  const setInsuranceDeclarationFlag = () => {
    setSavingDeclarationFlag(true);
    setFormData((prev) => ({
      ...prev,
      insuranceDeclarationFlag: {
        ...formData.insuranceDeclarationFlag,
        value: true,
        errorMessage: '',
      },
    }));
  };

  useEffect(() => {
    if (formData.insuranceDeclarationFlag.value && savingDeclarationFlag) {
      saveDraft(undefined, false, false, true);
    }
  }, [formData.insuranceDeclarationFlag.value, savingDeclarationFlag]);

  useEffect(() => {
    getPrivacyPolicy();
    getBanksList();
    getClassifiersList();
    getCustomFieldOptions().then((response) => {
      if (response.options) {
        setCustomFieldOptions(response.options.map((option) => ({ value: option.id, label: option.name })));
        setCustomFieldName(response.name);
      }
    });
  }, []);

  useEffect(() => {
    if (formData.bank.value.value && bankClassifier) {
      bankClassifier.map((data) => {
        let valueForSelect = {};
        if (checkDiscount(data[1], formData.bank.value.value)) {
          valueForSelect = { label: 'Скидка, %', value: 'discount' };
        } else {
          valueForSelect = { label: 'Надбавка, %', value: 'allowance' };
        }

        setAdjustmentType((prev) => ({
          ...prev,
          [data[0]]: {
            ...prev[data[0]],
            value: valueForSelect,
          },
        }));
      });
    }
  }, [formData.bank.value.value, bankClassifier]);

  useEffect(() => {
    getBanksClassifiersList();
  }, []);

  return (
    <>
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
        closeIconSize={16}
        open={modalGift}
        onClose={() => setModalGift(false)}
        center
      >
        <h3>Продукт Чек-ап в подарок к ипотечному страхованию от Страховой компании ВСК</h3>
        <p><div>При оформлении полиса ипотечного страхования ВСК дарит лабораторный чек-ап* в клиниках Инвитро!</div></p>
        <p>
          <div><b>Как воспользоваться</b></div>
          <ul>
            <li>После оформления полиса вы получите PDF-файл с договором
              страхования и сертификатом на чек-ап
            </li>
            <li>Зайдите в мобильное приложение и напишите в чате, что хотите
              воспользоваться чек-апом
            </li>
            <li>Сдайте анализы и пройдите бесплатную дистанционную консультацию с врачом</li>
          </ul>
        </p>
        <p>
          <div><b>Из чего состоит чек-ап</b></div>
          <div>Чек-ап состоит из лабораторного исследования и двух дистанционных консультаций с врачом. Мы дарим один из двух видов чек-апа, в зависимости от пола страхователя.</div>
        </p>
        <p>
          <div><b>Состав лабораторных исследований</b></div>
          <div><u>Женское здоровье:</u></div>
          <div>ОАК, Аланинаминотрансфераза (АЛТ) + Аспартатаминотрансфераза (АСТ), креатинин, общий билирубин, липидный спектр, глюкоза, ТТГ, гомоцестеин, цитологическое исследование соскобов шейки матки и цервикального канала, забор крови</div>
          <div><u>Мужское здоровье:</u></div>
          <div>ОАК, Аланинаминотрансфераза (АЛТ) + Аспартатаминотрансфераза (АСТ), креатинин, общий билирубин, липидный спектр, глюкоза, ПСА общий, тестостерон общий, забор крови</div>
        </p>
        <p>
          <div>
            *Чек-ап - проверка состояния здоровья, заключающаяся в медицинских услугах, указанных в данном материале.
            <br />
            Медицинские услуги оказываются ООО «Независимая лаборатория ИНВИТРО», лицензия №ЛО-16-01-003868 от 23.12.2014 г.
            <br />
            Медицинские услуги, в том числе указанные в данном материале, могут иметь противопоказания; перед получением услуги проконсультируйтесь со специалистом.
          </div>
        </p>
      </Modal>
      <Modal
        classNames={{ overlay: 'modal-window', closeButton: 'modalCloseButton', modal: 'modal-window-inner wide-window' }}
        closeIconSize={16}
        open={modalDeclarationIsOpen}
        onClose={() => {
          setModalDeclarationIsOpen(false);
          setFormData((prev) => ({
            ...prev,
            insuranceDeclarationFlag: {
              ...formData.insuranceDeclarationFlag,
              value: false,
              errorMessage: 'Необходимо подтвердить',
            },
          }));
        }}
        center
      >
        <>
          <div dangerouslySetInnerHTML={{ __html: htmlDeclaration }} />
          <button
            type="button"
            className="btn btn-success mr-2"
            onClick={() => {
              setModalDeclarationIsOpen(false);
              setFormData((prev) => ({
                ...prev,
                insuranceDeclarationFlag: {
                  ...formData.insuranceDeclarationFlag,
                  value: true,
                  errorMessage: '',
                },
              }));
            }}
          >
            Ознакомлен
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setModalDeclarationIsOpen(false);
              setFormData((prev) => ({
                ...prev,
                insuranceDeclarationFlag: {
                  ...formData.insuranceDeclarationFlag,
                  value: false,
                  errorMessage: 'Необходимо подтвердить',
                },
              }));
            }}
          >
            Отмена
          </button>
        </>
      </Modal>
      {(policyStatus === 'processing' || policyStatus === 'calculated') && (
        <FormNotificationColorLine offsetBottom="1rem">Данный черновик был проведен до стадии формирования ссылки на оплату и заблокирован для дальнейших изменений. Если вы хотите произвести повторный расчет - скопируйте черновик.</FormNotificationColorLine>
      )}
      {showMortgageCheckInfo && (
        <MortgageCheckInfo
          history={history}
          formData={formData}
          companies={companies}
          backToForm={backToForm}
          chosenOffer={chosenOffer}
          setChosenOffer={setChosenOffer}
          draftInfo={draftInfo}
          setDisableField={setDisableField}
          disableField={disableField}
          blurClass={blurClass}
          policy={policy}
          loadPolicy={loadPolicy}
        />
      )}
      <div name="mortgage_container" style={{ display: showMortgageCheckInfo ? 'none' : 'block' }}>
        <LoadingBanner loadingFlag={loadingMortgageInfo}>
          {formData.bank.value.value === 'sberbank' ? (
            <div className="form-group row">
              <div className="col-lg-12">
                <FormNotificationColorLine type="ok">
                  <div className="bank-info">
                    <img alt="sber_logo" src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${banks.sberbank.logo}`} className="bank-info__icon"/>
                    <div>
                      <h3 className="bank-info__header">{policyStatus === 'processing' || policyStatus === 'calculated' ? 'Оформили полис для Ипотеки Сбербанка?' : 'Оформляете полис в Сбербанке?'}</h3>
                      Вы сможете оформить и оплатить полис на нашем сайте. Все полисы подходят для Сбербанка. Если сделка оформляется через ДомКлик, просто загрузите полис и чек в раздел&nbsp;
                      <a href="https://insurance.domclick.ru/upload" target="_blank" rel="noopener noreferrer" className="dotted_link">«загрузка полиса»</a>
                    </div>
                  </div>
                </FormNotificationColorLine>
              </div>
            </div>
          ) : null}
          {formData.bank.value.value === 'vtb' ? (
            <div className="form-group row">
              <div className="col-lg-12">
                <FormNotificationColorLine type="error">
                  <div className="bank-info">
                    <img alt="vtb_logo" src={`${process.env.REACT_APP_BACKEND_DOMAIN}/${banks.vtb.logo}`} className="bank-info__icon"/>
                    <div><strong>Важно! При не соответствии данных клиента и/или данных кредитного договора, требованиям банка ВТБ, есть риск отказа банка от договора страхования. Внимательно проверьте данные перед оформлением! Обязательно проверяйте требования банка по конкретной сделке, уточняйте требования к полису у менеджера кредитной организации.</strong></div>
                  </div>
                </FormNotificationColorLine>
              </div>
            </div>
          ) : null}
          {!chosenOffer.companyCode || showForm === 'short' ? (
            <>
              <div className="card mb-4">
                <div className="card-body mortgage-product-switcher mb-3">
                  <h3 className="mortgage-product-switcher__header">Выберите тип полиса
                    <Tippy
                      className="tippy-custom"
                      arrow={false}
                      offset={[0, 5]}
                      content={(
                        <div>
                          Требования по типу полиса можно посмотреть в Кредитном договоре.
                          Если Имущество еще не введено в эксплуатацию и право собственности не получено, оформляется только тип Жизнь.
                          Если право собственности есть, то оформляется тип Имущество.
                          Если необходимо оформить страхование Титула, то обратитесь в Заявки на страхование из раздела Оформить.
                          Титул страхуется совместно с Имуществом.
                        </div>
                      )}
                      hideOnClick={false}
                    >
                      <div className="mortgage-options-header__icon">
                        <FontAwesomeIcon icon={faQuestionCircle} className="fa-fw mr-2 d-none d-lg-block" />
                      </div>
                    </Tippy>
                  </h3>
                  <div className={classnames('switcher-item', { 'switcher-item--active': draftInfo.type === 'mortgage' })} onClick={() => setDraftInfo((prev) => ({ ...prev, type: 'mortgage' }))}>
                    <FontAwesomeIcon icon={faHome} className="fa-fw" />
                    Имущество
                  </div>
                  <div className={classnames('switcher-item', { 'switcher-item--active': draftInfo.type === 'mortgageLife' })} onClick={() => setDraftInfo((prev) => ({ ...prev, type: 'mortgageLife' }))}>
                    <FontAwesomeIcon icon={faHeartbeat} className="fa-fw" />
                    Жизнь
                  </div>
                  <div className={classnames('switcher-item', { 'switcher-item--active': draftInfo.type === 'mortgageComplex' })} onClick={() => setDraftInfo((prev) => ({ ...prev, type: 'mortgageComplex' }))}>
                    <FontAwesomeIcon icon={faBriefcaseMedical} className="fa-fw" />
                    Жизнь и имущество
                  </div>
                </div>
              </div>
              {showCommercialOfferModalForm ? (
                <ModalCommercialOfferForm
                  classifiers={classifiers}
                  formData={formData}
                  setFormData={setFormData}
                  saveDraft={saveDraft}
                  phoneCheckRequestFlag={phoneCheckRequestFlag}
                  handleValidate={handleValidate}
                  disableField={disableField}
                  showCommercialOfferModalForm={showCommercialOfferModalForm}
                  setShowCommercialOfferModalForm={setShowCommercialOfferModalForm}
                  loadingCommercialOffer={loadingCommercialOffer}
                />
              ) : null}
              <MortgageShortForm
                adjustmentTypeOptions={adjustmentTypeOptions}
                setModalGift={setModalGift}
                modalGift={modalGift}
                discountPercentLoadingFlag={discountPercentLoadingFlag}
                setDiscountPercentLoadingFlag={setDiscountPercentLoadingFlag}
                discountPercent={discountPercent}
                allowancePercent={allowancePercent}
                adjustmentType={adjustmentType}
                setDiscountPercent={setDiscountPercent}
                setAllowancePercent={setAllowancePercent}
                setAdjustmentType={setAdjustmentType}
                disableField={disableField}
                formData={formData}
                setFormData={setFormData}
                saveDraft={saveDraft}
                bankOptions={bankOptions}
                handleValidate={handleValidate}
                companies={companies}
                goToFullForm={goToFullForm}
                draftInfo={draftInfo}
                goCalcFlag={goCalcFlag}
                setGoCalcFlag={setGoCalcFlag}
                blurClass={blurClass}
                setBlurClass={setBlurClass}
                showForm={showForm}
                toggleFlag={toggleFlag}
                backToForm={backToForm}
                loadingInit={loadingInit}
                setLoadingInit={setLoadingInit}
                selectedSort={selectedSort}
                setSelectedSort={setSelectedSort}
                policySortOrderUp={policySortOrderUp}
                setPolicySortOrderUp={setPolicySortOrderUp}
                policyIntegrationsData={policyIntegrationsData}
                setPolicyIntegrationsData={setPolicyIntegrationsData}
                allPolices={allPolices}
                setAllPolices={setAllPolices}
                showCommission={showCommission}
                setShowCommission={setShowCommission}
                commercialOfferCompanies={commercialOfferCompanies}
                setCommercialOfferCompanies={setCommercialOfferCompanies}
                setShowCommercialOfferModalForm={setShowCommercialOfferModalForm}
                showCommercialOfferModalForm={showCommercialOfferModalForm}
                commercialOfferRef={commercialOfferRef}
                setLoadingCommercialOffer={setLoadingCommercialOffer}
                setScrollTo={setScrollTo}
                policy={policy}
                setCursorToEnd={setCursorToEnd}
                classifiers={classifiers}
              />
            </>
          ) : null}
          {showForm === 'mortgage' || showForm === 'mortgageLife' || showForm === 'mortgageComplex' ? (
            <>
              {chosenOffer.companyCode ? (
                <MortgageChosenOffer
                  disableField={disableField}
                  chosenOffer={chosenOffer}
                  companies={companies}
                  backToForm={backToForm}
                  formData={formData}
                  draftInfo={draftInfo}
                />
              ) : null}
              <div className="form-group row">
                <div className="col-lg-12">
                  <h3>Оформление страхового полиса</h3>
                </div>

                {!policy.is_demo && !disableField ? (
                  <div className="pl-3">
                    <a rel="noopener noreferrer" href="#" onClick={(e) => backToForm(e, 'short')} className="page_back_link"><FontAwesomeIcon icon={faLongArrowAltLeft} className="fa-fw" />К выбору СК</a>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {showForm !== 'short' ? (
            <MortgageForm
              loadingDeclaration={loadingDeclaration}
              setInsuranceDeclarationFlag={setInsuranceDeclarationFlag}
              setModalDeclarationIsOpen={setModalDeclarationIsOpen}
              modalDeclarationIsOpen={modalDeclarationIsOpen}
              loadingPolicyTemplate={loadingPolicyTemplate}
              linkToDeal={linkToDeal}
              leadSource={leadSource}
              customField={customField}
              setLeadSource={setLeadSource}
              managerAttracted={managerAttracted}
              setManagerAttracted={setManagerAttracted}
              setLinkToDeal={setLinkToDeal}
              setCustomField={setCustomField}
              leadSourcesOptions={leadSourcesOptions}
              customFieldOptions={customFieldOptions}
              customFieldName={customFieldName}
              managerAttractedOptions={managerAttractedOptions}
              setFormData={setFormData}
              companies={companies}
              privacyPolicy={privacyPolicy}
              formData={formData}
              disableField={disableField}
              handleValidate={handleValidate}
              toggleFlag={toggleFlag}
              setCursorToEnd={setCursorToEnd}
              calcFlags={calcFlags}
              insuredAddressRegistrationRef={insuredAddressRegistrationRef}
              creditAgreementAddressRef={creditAgreementAddressRef}
              phoneCheckRequestFlag={phoneCheckRequestFlag}
              policy={policy}
              urlParams={urlParams}
              saveDraft={saveDraft}
              goToBuying={goToBuying}
              draftInfo={draftInfo}
              chosenOffer={chosenOffer}
              classifiers={classifiers}
              newAgent={newAgent}
              setNewAgent={setNewAgent}
            />
          ) : null}
        </LoadingBanner>
      </div>
    </>
  );
}

export default forwardRef(MortgageFormContainer);
